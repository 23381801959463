<template>
    <div class="ms-5 mb-3">
        <div class="row border bg-gray-3">
            <div class="col-1 text-start">
                <span><small>{{subquestion.id}}</small></span>
                <small class="ps-3">Sek.:</small>
                <input class="border-0 mt-1" type="text" v-model="subquestion.orderseq" maxlength="2" size="2" required>
            </div>
        <div class="col-3 ">
            <input type="text" class="smalltext form-control border-0 p-0 mt-1 " aria-describedby="inputGroup-sizing-default" :placeholder="$t('text')" v-model="subquestion.text" required>
        </div>
        <div class="col-3 text-start">
            <small>{{$t('selectquestiontype')}}</small>
            <select v-model="subquestion.type" class="ms-2 form-select-mini darksel" required>
                <option disabled value="0">{{$t('selectquestiontype')}}</option>
                <option v-for="type in questiontypes" v-bind:value="type">
                    {{ type.name }}
                </option>
            </select>
        </div>
        <div class="col-4 text-start">
            <small>{{$t('selecticon')}}</small>
            <select v-model="subquestion.icon" class="ms-2 form-select-mini darksel" required >
                <option disabled value="0">{{$t('selecticon')}}</option>
                <option v-for="icon in icons" v-bind:value="icon">
                    {{ icon.filename }}
                </option>
            </select>
        </div>
        <div class="col-1">
            <button type="button" v-on:click="deleteSubQuestion" class="ms-2 btn btn-islink btn-sm p-1"><font-awesome-icon icon="fa-trash" /></button>
        </div>
    </div>
    <div class="row">
        <div class="col-12 border text-start">
            <component is="QuestionTimePickers" :question="subquestion" :key="subquestion.id"></component>
        </div>
    </div>
    <div class="row">
            <div class="col-12 border text-start">
                <span class="me-1">{{$t('showinfo')}}</span>
                <input class="form-check-input mt-0" type="checkbox" v-model="subquestion.showinfo">
                <textarea maxlength="500" class="smalltext form-control" id="exampleFormControlTextarea1" rows="2" v-model="subquestion.infotext" required></textarea>
            </div>
        </div>
    </div>
    <!--
    <div class="ms-5">
        <div class="input-group input-group-sm">
            <span class="input-group-text" id="inputGroup-sizing-default">{{subquestion.id}}</span>
            <span class="input-group-text" id="inputGroup-sizing-default">Sek.:</span>
            <input type="text" class="input-group-text darksel" v-model="subquestion.orderseq" maxlength="2" size="2" required>

            <input type="text" class="form-control darksel" aria-describedby="inputGroup-sizing-default" :placeholder="$t('text')" v-model="subquestion.text" required>
            <span class="input-group-text" id="inputGroup-sizing-default">{{$t('selectquestiontype')}}:</span>

            <select v-model="subquestion.type" class="form-select-sm darksel" required>
                <option disabled value="0">{{$t('selectquestiontype')}}</option>
                <option v-for="type in questiontypes" v-bind:value="type">
                    {{ type.name }}
                </option>
            </select>

            <span class="input-group-text" id="inputGroup-sizing-default">{{$t('selecticon')}}:</span>
            <select v-model="subquestion.icon" class="form-select-sm darksel" required >
                <option disabled value="0">{{$t('selecticon')}}</option>
                <option v-for="icon in icons" v-bind:value="icon">
                    {{ icon.filename }}
                </option>
            </select>
            <a class="btn btn-islink m-0" data-bs-toggle="collapse" :href="'#sqtimesettings' + subquestion.id" role="button" aria-expanded="false" aria-controls="qtimesettings">
                {{$t('addtime')}}
            </a>
            <div class="collapse" :id="'sqtimesettings' + subquestion.id">
                <component is="QuestionTimePickers" :question="subquestion" :key="subquestion.id"></component>
            </div>
            <button type="button" v-on:click="deleteSubQuestion" class="btn-bottom btn btn-isdanger ">{{$t('delete')}}</button>
        </div>
    </div>
-->

</template>

<script>

import QuestionTimePickers from '@/components/QuestionTimePickers'
import SubQuestionService from '@/helpers/apiservices/subquestion/subquestion.service'

export default {
    name: 'SubQuestion',
    components: {
        QuestionTimePickers
    },
    props:[
        'subquestion',
        'icons',
        'index',
        'questionnaireid',
        'questiontypes'
    ],
    data() {
      return {
        selectedType: "",
      };
    },

    methods: {
        deleteSubQuestion() {
            this.delete();
        },
        async delete(){
            if( this.subquestion.id != 0){
                await SubQuestionService.delete(this.subquestion.id); 
            }
            this.$emit('removeItem', this.index);
        },

    }
    
}
</script>

<style>
.darksel {
    background: #e9ecef;
    background-color: #e9ecef !important;
}
</style>
    