<template>

    <div class="container d-flex flex-column min-vh-100 justify-content-center align-items-center">
        <h1>Takk for svar!
          <!--<img alt="Vue logo" class="m-5 thxicon" src="../../assets/thumpsupLarge.png">--></h1>
        <div class="mt-3 mb-3 card cstcard" v-if="tipofthedaystore.tipoftheday !== ''">
          <div class="card-body">
            <i class="fs-1"><font-awesome-icon icon="fa-lightbulb" /></i> 
            <h5 class="card-title">{{ $t("todaystip") }}</h5>
            <p class="card-text">{{tipofthedaystore.tipoftheday}}</p>
          </div>
        </div>
        <button class="btn btn-dark" @click="onClick">{{$t('backbtn')}}</button>
    </div>
</template>
  
<script>

  import router from '@/router';
  import { useRoute } from 'vue-router'
  import { tipofthedaystore } from '@/stores/tipoftheday.store.js'

    export default {
      name: 'Thanks',
      data() {
        return {
            returntosurvey: false,
            surveypublicid: String,
            tipofthedaystore
        }
      },
      mounted() {
        const route = useRoute();
        if(route.query.survey){
          this.returntosurvey = true;
          this.surveypublicid = route.query.survey;
        }
      },
      methods: {
        onClick : function(event) {
          if(this.returntosurvey){
            router.push({
              path: "Response",
              query: {id: this.surveypublicid.toUpperCase()}
            });
          }
          else{
            router.push({
              path: "/",
            });
          }
        },
      } 
    }
</script>

<style scoped>

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .thxicon{
      width: 50px; 
      height: 50px;
    }
    .cstcard{
      width: 300px;
    }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .thxicon{
      width: 50px; 
      height: 50px;
    }
    .cstcard{
      width: 300px;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .thxicon{
      width: 50px; 
      height: 50px;
    }
    .cstcard{
      width: 400px;
    }
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .thxicon{
      width: 50px; 
      height: 50px;
    }
    .cstcard{
      width: 400px;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .thxicon{
      width: 50px; 
      height: 50px;
    }
    .cstcard{
      width: 400px;
    }
  
  }
    </style>