import axios from "axios";
import ErrorParser from '@/services/error.parser';

const UserService = {
  login: async function (user) {
    const requestData = {
      method: "post",
      url: "/api/user/login",
      data: user 
    };
    try {
      const response = await axios(requestData);
      return JSON.parse(JSON.stringify(response.data));
    } catch (error) {
      const message = ErrorParser.asMessage(error);
      throw new Error(message);
    }
  },
  register: async function (user) {
    const requestData = {
      method: "post",
      url: "/api/user/register",
      data: user 
    };
    try {
      const response = await axios(requestData);
      return JSON.parse(JSON.stringify(response.data));
    } catch (error) {
      const message = ErrorParser.asMessage(error);
      throw new Error(message);
    }
  },
};

export default UserService;

