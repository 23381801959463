<!-- Update this component to support different types of subquestions -->

<template>
  <tbody>
      <!--When new templates for questions: each row contains of 8 columns-->
      <!-- Template for subquestion type = 24, 27, 32 -->
      <SliderQuery :question="subquery"  v-if="subquery.type.id==27"></SliderQuery>
      <SliderQuery :question="subquery"  v-if="subquery.type.id==24"></SliderQuery>
      <SliderQuery :question="subquery"  v-if="subquery.type.id==32"></SliderQuery>
      <SliderQuery :question="subquery"  v-if="subquery.type.id==33"></SliderQuery>
      <SmileySubQuery :question="subquery"  v-if="subquery.type.id==35"></SmileySubQuery>
      <TrueFalseSubQuery :question="subquery"  v-if="subquery.type.id==36"></TrueFalseSubQuery>
    </tbody>
</template>
  
<script>
  
    import SliderQuery from '@/components/survey/SliderQuery'
    import SmileySubQuery from '@/components/survey/SmileySubQuery'
    import TrueFalseSubQuery from '@/components/survey/TrueFalseSubQuery'
    export default {
      name: 'SubQuery',
      components:{
        SliderQuery,
        SmileySubQuery,
        TrueFalseSubQuery
      },
      props:{
          query: {type: Object},
          subquery: {type: Object},
      },  
    }
  </script>
  
  <style>
  
  </style>