import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/auth.store';
import Admin from './views/admin/Admin.vue'
import Login from './views/admin/Login.vue'
import Questionnaires from './views/admin/questionnaire/Questionnaires.vue'
import QuestionnaireEdit from './views/admin/questionnaire/QuestionnaireEdit.vue'
import QuestionTypes from './views/admin/questiontypes/QuestionTypes.vue'
import Icons from './views/admin/icon/Icons.vue'
import Survey from './views/admin/survey/Survey.vue'
import MySurveys from './views/admin/survey/MySurveys.vue'
import Response from './views/survey/Response.vue'
import Dashboard from './views/admin/dashboard/Dashboard.vue'
import Register from './views/admin/Register.vue'
import Welcome from './views/survey/Welcome.vue'
import Thanks from './views/survey/Thanks.vue'
import Actions from './views/admin/action/Actions.vue'
import ActionTemplates from './views/admin/actiontemplate/ActionTemplates.vue'
import OpenStatistics from './views/survey/OpenStatistics.vue'

const routes =  [
    {
        path: "/",
        name: "Welcome",
        component: Welcome,
        meta: { requiresAuth: false },
    },
    {
        path: "/admin",
        name: "Admin",
        component: Admin,
        meta: { requiresAuth: true },
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: { requiresAuth: false },
    },
    {
        path: "/register",
        name: "Register",
        component: Register,
        meta: { requiresAuth: false },
    },
    {
        path: "/Questionnaires",
        name: "Questionnaires",
        component: Questionnaires,
        meta: { requiresAuth: true },
    },
    {
        path: "/QuestionnaireEdit",
        name: "QuestionnaireEdit",
        component: QuestionnaireEdit,
        meta: { requiresAuth: true },
    },
    {
        path: "/questiontypes",
        name: "QuestionTypes",
        component: QuestionTypes,
        meta: { requiresAuth: true },
    },
    {
        path: "/icons",
        name: "Icons",
        component: Icons,
        meta: { requiresAuth: true },
    },
    {
        path: "/survey",
        name: "Survey",
        component: Survey,
        meta: { requiresAuth: true },
    },
    {
        path: "/mysurveys",
        name: "MySurveys",
        component: MySurveys,
        meta: { requiresAuth: true },
    },
    {
        path: "/actions",
        name: "Actions",
        component: Actions,
        meta: { requiresAuth: true },
    },
    {
        path: "/actiontemplates",
        name: "ActionTemplates",
        component: ActionTemplates,
        meta: { requiresAuth: true },
    },
    {
        path: "/response",
        name: "Response",
        component: Response,
        meta: { requiresAuth: false },
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: { requiresAuth: false },
    },
    {
        path: "/Thanks",
        name: "Thanks",
        component: Thanks,
        meta: { requiresAuth: false },
    },
    {
        path: "/openstatistics",
        name: "OpenStatistics",
        component: OpenStatistics,
        meta: { requiresAuth: false },
    },
]

let router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const authRequired = to.matched.some(record => record.meta.requiresAuth);
    const auth = useAuthStore();

    if (authRequired && !auth.user) {
        auth.returnUrl = to.fullPath;
        return '/login';
    }
});
export default router;