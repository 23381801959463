
<template>
     <!--<img alt="IDGG MIN SKOLE logo" src="../../assets/Dms_logo_2.png"/>-->
    <div class="container-fluid qcontainer">
        <div v-if="notfound">
            <div class="fs-4 mb-3">{{$t('surveynotfoundtitle')}}</div>
            <div>{{$t('surveynotfoundbody1')}}</div>
            <div>{{$t('surveynotfoundbody2')}}</div>
            <div>{{$t('surveynotfoundbody3')}}</div>
            <button class="mt-4 btn btn-sm btn-dark btn-md btn-block" @click="onBackClick">
                <font-awesome-icon icon="fa-solid fa-arrow-left" />
                {{$t('backbtn')}} 
            </button>
        </div> 
        <div v-else>
            <div v-if="isloading">{{$t('loadingsurvey')}}</div>

            <div v-else>
                <div class="align-items-center text-center m-2">
                    <img style="height: 50px ; width: 100px" alt="IDGG MIN SKOLE logo" src="../../assets/Dms_logo_2.png"/>
                </div>
                
                <div class="m-lg-1" >
                    <table class="table table-borderless">
                        <!-- handling one-row for each question-->
                        <template v-if="questionnaire.numberofCols === 1">
                            <SingleColumnQuestionsVue :questionnaire="questionnaire"></SingleColumnQuestionsVue>
                        </template>
                    
                        <!-- Handling multi-columned questions-->
                        <template v-else>
                            <MultiColumnQuestions :questionnaire="questionnaire"></MultiColumnQuestions>
                        </template>
                    
                    </table>
                </div>
            </div>
            <!--END V-ELSE-->
        </div>
    </div>

</template>
  
  
<script>

    import router from '@/router';
    import { useRoute } from 'vue-router'
    import QuestionnaireService from "@/helpers/apiservices/questionnaire/questionnaire.service"
    import SingleColumnQuestionsVue from '@/components/survey/SingleColumnQuestions.vue';
    import MultiColumnQuestions from '@/components/survey/MultiColumnQuestions.vue';

    export default {
        name: 'Response',
        components: {
            SingleColumnQuestionsVue,
            MultiColumnQuestions
        }, 
        data() {
            return {
                questionnaire: {},
                isloading: true,
                notfound: false
            }
        },
        mounted() {

            this.loadInitialData();

        },
        methods: {
            async loadInitialData() {
                this.isloading = true;
                const route = useRoute();
                if(route.query.id){
                    const now = new Date();
                    const currenttime = now.getHours() + ':' + now.getMinutes();
                    try {
                        this.questionnaire = await QuestionnaireService.questionnairesurveywithpid(route.query.id, currenttime, now.getDay());
                        this.isloading = false;
                    } 
                    catch (e) {
                        this.isloading = false;
                        this.notfound = true;
                    }
                }
            },
            onBackClick : function(event) {
                router.push({
                    path: "/",
                });
            }, 
          },
          computed: {

            
          },
        }
    </script>
  
    <style scoped>
        .xstext{
            font-size: 11px;
        }
        /* Extra small devices (phones, 600px and down) */
        @media only screen and (max-width: 600px) {
            .qcontainer img {
                height: 50px ;
                width: 150px;
            }
            .subtitle{
                font-size: 15px;
                font-weight: bold;
            }
        }

        /* Small devices (portrait tablets and large phones, 600px and up) */
        @media only screen and (min-width: 600px) {}

        /* Medium devices (landscape tablets, 768px and up) */
        @media only screen and (min-width: 768px) {
            .qcontainer{
                justify-content: center !important;
                flex-direction: column !important;
                min-height: 100vh !important;
                display: flex !important;
                width: 100%;
            }
            .qcontainer img {
                height: 150px ;
                width: 350px;
            }
            .subtitle{
                font-size: 25px;
                font-weight: bold;
            }
        }

        /* Large devices (laptops/desktops, 992px and up) */
        @media only screen and (min-width: 992px) {
            .qcontainer{
                justify-content: center !important;
                flex-direction: column !important;
                min-height: 100vh !important;
                display: flex !important;
                width: 85%;
            }
            .qcontainer img {
                height: 150px ;
                width: 350px;
            }
            .subtitle{
                font-size: 25px;
                font-weight: bold;
            }
        }

        /* Extra large devices (large laptops and desktops, 1200px and up) */
        @media only screen and (min-width: 1200px) {
            .qcontainer{
                justify-content: center !important;
                flex-direction: column !important;
                min-height: 100vh !important;
                display: flex !important;
                width: 70%;
            }
            .qcontainer img {
                height: 150px ;
                width: 350px;
            }
            .subtitle{
                font-size: 25px;
                font-weight: bold;
            }
        }

    </style>