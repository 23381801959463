<template>

    <tr v-if="edit">
        <td class="align-middle">{{icon.id}}</td>
        <td><input type="text" maxlength="250" class="form-control text-align-bottom" aria-describedby="basic-addon1" v-model="icon.description"></td>
        <td><input type="text" maxlength="250" class="form-control" aria-describedby="basic-addon1" v-model="icon.filename"></td>
        <td class="text-end"><button type="button" v-on:click="updateIcon" class="m-1 btn btn-islink btn-sm fw-bold">{{$t('save')}}</button></td>  
    </tr>
    <tr v-else>
        <td class="align-middle">{{icon.id}}</td>
        <td class="align-middle text-start">{{icon.description}}</td>
        <td class="align-middle text-start">{{icon.filename}}</td>
        <td class="text-end">
            <button @click="setEdit" class="m-1 btn btn-islink btn-sm fw-bold">{{$t('edit')}}</button>
            <button @click="deleteIcon" class="m-1 align-middle btn btn-islink btn-sm fw-bold link-isdanger">{{$t('delete')}}</button>
        </td>
    </tr>

</template>

<script>

import IconService from "@/helpers/apiservices/icon/icon.service"
import Notify from "@/helpers/notify";

export default {
    name: 'Icon',
    props:[
        'icon'
    ],
    data() {
      return {
        edit: false
      };
    },
    methods: {
        async deleteIcon() {
            Notify.loadData("deleted", this.delete);
        },
        async updateIcon() {
            Notify.loadData("updated", this.update);
            
        },
        async delete(){
            await IconService.delete(this.icon);
            this.$emit('loadInitialData');
        },
        setEdit(){
            this.edit = !this.edit;
        },
        async update(){
            await IconService.update(this.icon);
            this.setEdit();
            this.$emit('loadInitialData');
        },

    }
    
}
</script>

<style>
    td{padding:0px !important}
</style>
    