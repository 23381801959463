import { createI18n } from "vue-i18n";

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: process.env.VUE_APP_I18N_LOCALE || "no",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "no",
  messages: {        
    no: {
      administration: 'Administrasjon',
      hjem: 'Hjem',
      dashboard: 'Dashboard',
      login: 'Logg inn',
      email: 'Epost',
      password: 'Passord',
      questionnaire: 'Spørreskjema',
      newquestionnaire: 'Nytt spørreskjema',
      survey: 'Spørreundersøkelse',
      newsurvey: 'Ny spørreundersøkelse',
      createsurvey: 'Opprett spørreundersøkelse',
      updatesurvey: 'Oppdater spørreundersøkelse',
      deletesurvey: 'Slett spørreundersøkelse',
      mysurveys: 'Mine spørreundersøkelser',
      newquestionnaire: 'Nytt spørreskjema',
      questiontype: 'Spørsmålstyper',
      newquestiontype: 'Legg til ny type',
      description: 'Beskrivelse',
      add: 'Legg til',
      delete: 'Slett',
      title: 'Tittel',
      edit: 'Endre',
      save: 'Lagre',
      qnairename: 'Navn på spørreskjema',
      name: 'Navn',
      nameinfo: 'Valgfritt navn',
      diggminskole: 'DIGG-MIN-SKOLE',
      text: 'Tekst',
      selectquestiontype: 'Velg type',
      newquestion: 'Nytt spørsmål',
      savequestionnaire: 'Lagre spørreskjema',
      question: 'Spørsmål',
      emsgMissingQTypes: 'Alle spørsmål må ha en valgt type',
      icons: 'Ikoner',
      newicon: 'Registrer nytt ikon',
      filename: 'Filnavn',
      selecticon: 'Velg ikon',
      hassubquestions: 'Tillat delspørsmål',
      newsubquestion: 'Legg til delspørsmål',
      subquestion: 'Delspørsmål',
      schoolname: 'Navn på bygning',
      classname: 'Navn på rom',
      roomid: 'Identifisering av rom',
      roomidinfo: 'Legg til informasjon som kan være til hjelp for å knytte svarene opp mot andre data (sd-anlegg, sensorer)',
      newschool: 'Legg til ny bygning',
      newclassroom: 'Legg til nytt rom',
      name: 'Navn',
      close: 'Lukk',
      addnew: 'Legg til',
      chooseschool: 'Velg bygning',
      chooseclassroom: 'Velg rom',
      choosequestionnaire: 'Velg spørreskjema',
      nopeopleoriginal: 'Antall personer originalt estimert for',
      nopeoplecurrent: 'Antall personer nå',
      peopleinRoom: 'Personene i rommet',
      hasreceivedprojectinfo: 'har informasjon om prosjektet',
      hasreceivedtopicinfo: 'har informasjon om inneklima',
      building: 'Bygning',
      room: 'Rom',
      openqrcode: 'QR KODE',
      qrcode: 'QR kode',
      print: 'Skriv ut',
      nextupper: 'NESTE',
      startsurvey: 'Start undersøkelse',
      numberofresponses: 'Antall besvarelser',
      goodlabel: 'Svært fornøyd',
      mediumlabel: 'Fornøyd',
      badlabel: 'Lite fornøyd',
      verybadlabel: 'Misfornøyd',
      noaccount: 'Har du ikke en konto?',
      registerhere: 'Registrer her',
      regemailpassw: 'Oppgi epost og valgfritt passord',
      register: 'Registrer',
      hasaccount: 'Har allerede en konto?',
      loginhere: 'Logg inn her',
      emailrequired: 'Epost er obligatorisk',
      truefalsevalue: 'True/false verdi',
      integervalue: 'Integer verdi',
      min: 'Min',
      max: 'Max',
      intrange: 'Integer range',
      insteps: 'Trinn',
      hexstart: 'Hex start',
      hexend: 'Hex slutt',
      hexsteps: 'Hex trinn (skill med komma)',
      starticon: 'Startikon',
      endicon: 'Sluttikon',
      intstartvalue: 'Startverdi',
      timeforq: 'Tidspunkt: ',
      always:'Alltid',
      spesificdays: 'Kun på dager',
      spesifictime: 'Kun mellom klokkeslett',
      monday: 'Mandag',
      tuesday: 'Tirsdag',
      wednesday: 'Onsdag',
      thursday: 'Torsdag',
      friday: 'Friday',
      saturday: 'Lørdag',
      sunday: 'Søndag',
      betweentime: 'Mellom klokken',
      addtime: 'Sett tid',
      dashboardcardinfo: 'Se svarstatistikk for dine undersøkelser',
      surveycardinfo: 'Lag nye spørreundersøkelser',
      mysurveyscardinfo: 'En oversikt over alle dine spørreundersøkelser',
      code: 'Kode',
      printqrcode: 'Skriv ut QR koden',
      mysurveysinfo1:'Her finner du en liste over alle undersøkelser du har opprettet.',
      mysurveysinfo2: 'QR koden åpner et eget vindu for utskrift med informasjon om undersøkelsen.',
      mysurveysinfo3: 'Dersom du klikker på tittelen på en i listen, kan du se mer detaljer og endre undersøkelsen',
      surveytype: 'Type',
      newsurveyinfo1:'Her registrerer du en ny, oppdaterer eller sletter spørreundersøkelse.',
      newsurveyinfo2:'Gi spørreundersøkelsen et valgtfritt navn og velg type spørreskjema. Velg deretter bygning og rom der spørreundersøkelsen skal gjennomføres. ',
      newsurveyinfo3:'Tilgjengelige rom i bygningen du har valgt, vil du finne i nedtrekkslisten etter at du har valgt bygning. Du kan enkelt legge til nye bygninger eller rom ved å trykke på lenkene under nedtrekkslistene. ',
      questionnaireinfo1: 'Her finner du en liste over alle opprettede spørreskjemaer.',
      questionnaireinfo2: 'Lenken for å gjøre endringer på spørreskjemaet, finner du i høyre kolonne.',
      questionnaireinfo3:' Dette åpner en egen side der du kan legge til, endre og slette spørsmål.',
      qeditinfo1: 'Her kan du opprette et nytt spørreskjema som kan benyttes til å opprette spørreundersøkelser.',
      qeditinfo2: 'Skjemaet bygges opp med spørsmål og delspørsmål. Velg en passende tekst for spørsmålet, velg type (dette bestemmer hvordan spørsmålet vil vises på skjermen) og velg et ikon som passer til. Det er mulig å velge tidspunkt for når spørsmålet skal vises feks kun på bestemte dager og/eller kun mellom gitte tidspunkt.',
      qeditinfo3: 'Rekkefølgen på spørsmålene kan endres ved å endre sekvensnummeret (kolonnen Sek.:). ',
      qeditinfo4: 'Informasjon om og mulighet for å legge til spørsmålstyper og ikoner, finner du under menyen Administrasjon.',
      iconinfo1: 'Her finner du en liste over alle registrerte ikoner. Disse benyttes i forbindelse med spørsmål i spørreskjemaet. ',
      iconinfo2: 'På denne siden kan du også enkelt legge til nytt, endre eller slette eksisterende ikon.',
      iconinfo3: 'Filnavnet som oppgis må være tilgjengelig på serveren. Ta kontakt med utvikler av portalen for en liste over tilgjengelige ikoner eller legge til flere.',
      qtypeinfo1: 'Her kan du legge til en ny spørsmålstype. Disse typene brukes når man lager et spørreskjema og bestemmer hvordan spørsmålet ser ut på skjermen.',
      qtypeinfo2: 'Dersom man velger et start og sluttikon så vil dette vises før og etter spørsmålet. Dette er særlig benyttet for glidegrafer (slider). Min, max, trinn og startverdi brukes for å bestemme verdier for glidegrafen. Man kan også benytte fargekoder i hex for hvert trinn.',
      qtypeinfo3: 'Under finner du også en liste over alle spørsmålstyper som allerede er registrert. Disse kan endres og slettes.',
      confirmpassword: 'Gjenta passord',
      choosedates: 'Velg tidsperiode',
      overview: 'Oversikt',
      detailedview: 'Detaljert',
      textview: 'Tekstbasert',
      norespons: 'Ikke svart',
      search: 'Søk',
      download: 'Last ned',
      dashboardinfo1: 'Her finner du en oversikt over svarene på spørreundersøkelsene din.',
      dashboardinfo2: 'Velg spørreundersøkelse i nedtrekksliten og trykk Søk for å hente statistikker. Statistikken vil være fra i dag og ett år tilbake i tid. Ønsker du å se mer detaljerte svar som inkluderer delspørsmål for hvert hovedspørsmål, velger du fanen Detaljert.',
      dashboardinfo3: 'En pdf-utskrift av statistikken du ser, kan gjøres ved å velge Last ned.',
      addtext: 'Info tekst',
      showinfo: 'Vis informasjonsikon',
      text: 'Tekst',
      verysatisfied: 'Svært fornøyd',
      notsatisfied: 'Ikke fornøyd',
      surveynotfoundtitle: 'Vi fant ikke spørreundersøkelsen',
      surveynotfoundbody1: 'Er du sikker på at du har skrevet inn riktig kode?',
      surveynotfoundbody2: 'Noen ganger kan for eksempel tallet 5 og bokstaven S ligne mye på hverandre.',
      surveynotfoundbody3: 'Du kan bruke tilbakeknappen under for å prøve igjen.',
      backbtn: 'TILBAKE',
      loadingsurvey: 'Bare vent litt mens vi leter frem spørreundersøkelsen',
      yeslabel: 'JA',
      nolabel: 'NEI',
      welcometitle: 'Velkommen til DIGG MIN SKOLE sin ikonbaserte spørreundersøkelse',
      actions: 'Tiltak/tips',
      actionsinfo1: 'Her finner du en liste over alle registrerte tiltak og tips. Disse benyttes i forbindelse med spørsmål i spørreskjemaet. ',
      actionsinfo2: 'På denne siden kan du også enkelt legge til nytt, endre eller slette eksisterende tiltak og tips.',
      actiondescription: 'Beskrivelse av tiltak/tips',
      actiontemplates: 'Mal for tiltak/tips',
      actiontemplatesinfo1: 'Her finner du en liste over alle registrerte maler for tiltak og tips. Disse er spesifikke for de ulike spørreskjemaene og vil generere foreslåtte tiltak/tips for hvert gjennomførte svar eller på gruppenivå. Merk at en type tiltaksmal/tipsmal ikke vil fungerer for en annen.',
      actiontemplatesinfo2: 'Dersom du ønsker tilpassede og spesiallagede tiltak/tips, ta kontakt med NILU for utvikling.',
      actiontemplatesinfo3: 'På denne siden kan du også enkelt legge til nytt, endre eller slette eksisterende mal for tiltak og tips.',
      actiontemplatestitle: 'Tittel på mal',
      actiontemplatesdescription: 'Beskrivelse av mal',
      useactiontemplate: 'Benytt mal for tiltak/tips',
      chooseactiontemplate: 'Velg mal for tiltak/tips',
      todaystip: 'Dagens tips',
      selectDateRange: 'Velg periode',
      clear: 'Fjern',
      chooseSurvey: 'Velg spørreundersøkelse',
      dashboardrrmsg: 'Tidsperiode og spørreskjema må være angitt ',
      actioninfo1: 'Her ser du tips laget basert på svarene i spørreundersøkelsen',
      actioninfo2: 'Mal for tiltak benyttet:',
      actioninfo3: 'Når mer enn 20% har svart bekreftende på et underspørsmål, blir det foreslått tiltak. Noen tiltak vil også komme som generelle tips',
      responsedistribution: 'Svarfordeling',
      report: 'Rapport',
      totansinperiod: 'Totalt antall besvarelser i løpet av perioden:',
      responsdistribution: 'Svarfordeling',
      responsdistributiontxt: 'Denne grafen er en enkelt fremstilling av tidspunkter i løpet av dagen elever har svart på undersøkelsen.',
      mainqanswer: 'Svar på hovedspørsmål',
      mainqanswertxt: 'Kakediagrammene under viser en fordeling av svarene på spørreundersøkelsens hovedspørsmål i rommet.  Det er mulig å velge å ikke svare på spørsmål og dette vises i diagrammet som «Ikke svart»',
      subqanswer: 'Svar på delspørsmål',
      subqanswertxt1: 'Dersom man velger det røde smilefjeset til et hovedspørsmål, vil man bli spurt om å gi litt mer informasjon. Tabellene under viser antall personer som har svart JA på delspørsmål. ',
      subqanswertxt2: '«Støy i rommet nå» har ingen delspørsmål.',
      subtitlestart: 'Undertekst start',
      subtitleend: 'Undertekst slutt',
      numberofcols: 'Antall kolonner per rad',
      subqtitle: 'Delspørsmål',
      backgroundhex: 'Bakgrunnsfarge (hex)',
      openstatsTitleToday: 'Tilbakemeldinger i dag',
      openstatsTitleAll: 'Historiske tilbakemeldinger',
      openstatscoltitle1: 'Problem',
      openstatscoltitle12: 'Antall tilbakemeldinger',
      youranswer: 'Ditt svar: ',
      miniistesttext1: 'Takk for at du meldte fra om hvordan du opplever inneklimaet akkurat nå. Vi setter stor pris på at du hjelper oss med å teste denne løsningen. Din tilbakemelding er viktig for at vi skal kunne forbedre miljøet innendørs. Svaret ditt blir nå lagret slik at skolen kan se det.',
      miniistesttext2: 'Du kan gjerne melde inn flere ganger, og oppmuntre de andre i rommet til å gjøre det samme. Det vil ta 1 minutt før du kan melde inn igjen.',
      audiolanguagedescription: 'Trykk på det smilefjeset du synes passer best til spørsmålet: '

  }},
});