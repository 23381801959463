<template>

    <div class="mt-5">
        <p class="mt-5 fw-bold text-start">{{ $t("responsdistribution") }} </p>
        <p class="text-start">{{ $t("responsdistributiontxt") }} </p>
        <ResponseDistributionView :responses="responses" ></ResponseDistributionView>
        <p class="mt-5 fw-bold text-start">{{ $t("mainqanswer") }} </p>

        <div class="row">
          <div class="col-4"><img alt="main questionnaire page" src="../../assets/mainqpage.png"></div>
          <div class="col-8"><p class="text-start">{{ $t("mainqanswertxt") }} </p></div>
        </div>
        <OverView :statistics="statistics"></OverView>

        <p class="mt-5 fw-bold text-start">{{ $t("subqanswer") }} </p>
        <p class="text-start">{{ $t("subqanswertxt1") }} </p>
        <p class="text-start">{{ $t("subqanswertxt2") }} </p>
        <template v-for="(qr, index) in statistics.questionresponses" :key="qr.question.id">
          <div class="pt-3 row border">
            <div class="col-4 text-start">
              {{qr.question.text}}
            </div>
            <div class="col-8">
              <template v-for="subqresponse in qr.subquestionresponses">
                <div class="row">
                    <div class="col-8 text-start">
                        <div>{{subqresponse.subquestion.text}}</div>
                    </div>
                    <div class="col-2">
                      <div v-if="subqresponse.numericcount != null">
                          <StatBarChart :numericcount="subqresponse.numericcount" :booleancount="subqresponse.booleancount" :qid="qr.question.id" :sqid="subqresponse.subquestion.id"></StatBarChart>
                      </div>
                  </div>
                </div>
            </template>
            </div>
          </div>
          
      </template>

        
    </div>
  </template>

  <script>

    import ResponseDistributionView from '@/components/statistics/ResponseDistributionView';
    import DetailedView from '@/components/statistics/DetailedView';
    import OverView from '@/components/statistics/OverView';
    import StatBarChart from '@/components/charts/StatBarChart';

    export default {
      name: 'ReportView',
      components:{
        ResponseDistributionView,
        DetailedView,
        OverView,
        StatBarChart
      },
      props:{
        statistics: {type: Object},
        responses: {type: Array}
      },
    }
    </script>
  
    <style>
  
    </style>