<template>

    <tr v-if="edit">
        <td class="align-middle">{{actiontemplate.id}}</td>
        <td><input type="text" maxlength="250" class="form-control text-align-bottom" aria-describedby="basic-addon1" v-model="actiontemplate.title"></td>
        <td>
            <textarea maxlength="500" class="form-control" rows="4" v-model="actiontemplate.description"></textarea>
        </td>
        <td class="text-end"><button type="button" v-on:click="updateActionTemplate" class="m-1 btn btn-islink btn-sm fw-bold">{{$t('save')}}</button></td>  
    </tr>
    <tr v-else>
        <td class="align-middle">{{actiontemplate.id}}</td>
        <td class="align-middle text-start">{{actiontemplate.title}}</td>
        <td class="align-middle text-start">{{actiontemplate.description}}</td>
        <td class="text-end">
            <button @click="setEdit" class="m-1 btn btn-islink btn-sm fw-bold">{{$t('edit')}}</button>
            <button @click="deleteActionTemplate" class="m-1 align-middle btn btn-islink btn-sm fw-bold link-isdanger">{{$t('delete')}}</button>
        </td>
    </tr>

</template>

<script>

import ActionTemplateService from "@/helpers/apiservices/actiontemplate/actiontemplate.service"
import Notify from "@/helpers/notify";

export default {
    name: 'ActionTemplate',
    props:[
        'actiontemplate'
    ],
    data() {
      return {
        edit: false
      };
    },
    methods: {
        async deleteActionTemplate() {
            Notify.loadData("deleted", this.delete);
        },
        async updateActionTemplate() {
            Notify.loadData("updated", this.update);
            
        },
        async delete(){
            await ActionTemplateService.delete(this.actiontemplate);
            this.$emit('loadInitialData');
        },
        setEdit(){
            this.edit = !this.edit;
        },
        async update(){
            await ActionTemplateService.update(this.actiontemplate);
            this.setEdit();
            this.$emit('loadInitialData');
        },

    }
    
}
</script>

<style>
    td{padding:0px !important}
</style>
    