

<template>
  <div class="container-fluid">
    <QRCodeModal></QRCodeModal>
    <div class="row flex-nowrap">
      <div class="col-3"></div>
      <div class="mt-5 col-8 py-3">
        <div class="ms-3 text-start d-flex align-items-center">
          <i class="fs-1"><font-awesome-icon icon="fa-user" /></i> 
          <h5 class="ms-2 card-title">{{ $t("mysurveys") }}</h5>  
        </div>
        <div class="mt-3 ms-4 d-flex flex-column align-items-start">
          <span>
            {{$t('mysurveysinfo1')}}
          </span>
          <span>
            {{$t('mysurveysinfo2')}}
          </span>
          <span>
            {{$t('mysurveysinfo3')}}
          </span>
        </div>
        <div>
          <table class="mt-5 table table-striped">
            <thead>
              <tr>
                <th scope="col" class="text-start">
                  <span>
                    <font-awesome-icon icon="fa-solid fa-square-poll-vertical"></font-awesome-icon> 
                    {{$t('title')}}
                  </span>
                </th>
                <th scope="col" class="text-start">
                  <span>
                    <font-awesome-icon icon="fa-file-invoice" /> {{$t('surveytype')}}
                  </span>
                </th>
                <th scope="col" class="text-start">
                  <span>
                    <font-awesome-icon icon="building" /> {{$t('building')}}
                  </span>
                </th>
                <th scope="col" class="text-start">
                  <span>
                  <font-awesome-icon icon="door-open" /> {{$t('room')}}
                  </span>
                </th>
                <th scope="col" class="text-start">
                  <span>
                    <font-awesome-icon icon="fa-key" />
                    {{$t('code')}}
                  </span>
                </th>
                <th scope="col" class="text-middle">{{$t('printqrcode')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="survey in surveys" :key="survey.id">
                <td  @click="viewsurvey(survey.id)" class="ps-2 text-start align-middle pointer">{{ survey.title }}</td>
                <td class="ps-2 text-start align-middle">{{ survey.questionnaire.title }}</td>
                <td class="ps-2 text-start align-middle">{{ survey.building.text }}</td>
                <td class="ps-2 text-start align-middle">{{ survey.room.text }}</td>
                <td class="ps-2 text-start align-middle">{{ survey.public_id }}</td>
                <td class="ps-2 text-middle">                      
                  <button @click="openqrcode" data-bs-toggle="modal" :data-bs-url="survey.public_id" :data-bs-title="survey.title" 
                  :data-bs-questionnaire="survey.questionnaire.title" :data-bs-building="survey.building.text" :data-bs-room="survey.room.text"  
                  :data-bs-object="survey" data-bs-target="#qrcodeModal" class="m-0 p-0 btn btn-islink">
                  <font-awesome-icon icon="qrcode" class="fa-2x" />
                </button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  </template>
  
  <script>
  
  import router from '@/router';
  import Notify from "@/helpers/notify";
  import SurveyService from "@/helpers/apiservices/survey/survey.service"
  import Questionnaire from '@/components/Questionnaire'
  import QRCodeModal from "@/components/QRCodeModal"
  
  export default {
    name: 'MySurveys',
    data() {
      return {
        surveys:[],
      };
    },
    components: {
        QRCodeModal
    },
    mounted() {
        Notify.loadData(null, this.loadInitialData);
    },
    methods: {
      async loadInitialData() {
        this.surveys = await SurveyService.surveys();
      },
      viewsurvey(id){
        router.push({
            path: "Survey",
            query: {id: id}
        });
      }
    }
    
  }
  </script>
  
  <style>
    .pointer {
      cursor: pointer; 
    }
  
  </style>
    