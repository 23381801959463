<template>

    <div class="mt-5 row">
        <template v-for="qr in statistics.questionresponses" :key="qr.question.id">
            <StatPieChart :questionresponse="qr" :noresponses="statistics.noresponses"></StatPieChart>
        </template>
    </div>
  </template>
  <script>

  import StatPieChart from '@/components/charts/StatPieChart';

    export default {
      name: 'OverView',
      components:{
        StatPieChart
      },
      props:{
        statistics: {type: Object}
      },
    }
    </script>
  
    <style>
  
    </style>