<template>
    <Teleport to="body">
        <div class="modal fade" id="questionInfoModal" tabindex="-1" aria-labelledby="roomLabel" aria-hidden="true">
            <div class="modal-dialog modal-sm modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body text-start">
                        {{text}}
                    </div>
                    <div class="modal-footer">
                        <button class=" align-items-center btnemoji" @click="onTextToSpeech">
                            <font-awesome-icon icon="fa-volume-high" :style="{ color: 'gray' }" />
                        </button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{$t('close')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script>

import { Modal } from 'bootstrap'
import {SpeechService} from '@/services/SpeechService' 

    export default {
        name: 'QuestionInfoModal.vue',
        data() {
          return {
            text: ''
          };
        },
        mounted() {
            var vm = this;
            var questionInfoModal = document.getElementById('questionInfoModal');
            questionInfoModal.addEventListener('shown.bs.modal', function () {
                var button = event.relatedTarget;
                // Extract info from data-bs-* attributes
                vm.text  = button.getAttribute('data-bs-text');
            });
        },
        methods: {
            onTextToSpeech : async function(event) {
                if (this.hasSpeech) {
                    
                    var text = this.text;

                    //Testing OpenAI API
                    await SpeechService.speak("", text);

                    /*
                    text = text.replace('NÅ', ', NÅ'); //Create some space/pause before speaking the norwegian word NÅ
                    const utterThis = new SpeechSynthesisUtterance(text);
                    
                    const synth = window.speechSynthesis;
                    utterThis.rate = 0.4;
                    utterThis.lang = 'nb-NO';
                    synth.speak(utterThis);
                    */
                    
                }
            }
        },
        computed: {
          hasSpeech: function(){
            if ('speechSynthesis' in window) {
              return true;
            }
            return false;
          },
        }
       
    }
</script>

<style scoped>
    .fa{
      background: radial-gradient(black 50%, transparent 50%);
    }
    .btnemoji{
      background-color: transparent;
      border-style: none;
    }
</style>
                    