import axios from "axios";
import ErrorParser from '@/services/error.parser';
import { useAuthStore } from '@/stores/auth.store';

const QuestionTypeService = {
  questiontypes: async function () {

    const requestData = {
        method: "get",
        url: "/api/questiontype",
        headers: {
            "authorization": "Bearer " + useAuthStore().user.jwt 
        }
      };

    const response = await axios(requestData);
    return JSON.parse(JSON.stringify(response.data));

  },
  store: async function (questiontype) {
    const requestData = {
      method: "post",
      url: "/api/questiontype",
      data: questiontype,
      headers: {
        "authorization": "Bearer " + useAuthStore().user.jwt  
        } 
    };

    const response = await axios(requestData);
    return JSON.parse(JSON.stringify(response.data));
  },
  delete: async function (questiontype) {
    const requestData = {
      method: "delete",
      url: "/api/questiontype/" + questiontype.id,
      headers: {
        "authorization": "Bearer " +  useAuthStore().user.jwt  
        } 
    };

    const response = await axios(requestData);
    return JSON.parse(JSON.stringify(response.data));

  },
  update: async function (questiontype) {
    const requestData = {
      method: "put",
      url: "/api/questiontype/" + questiontype.id,
      data: questiontype,
      headers: {
        "authorization": "Bearer " + useAuthStore().user.jwt  
        } 
    };

    const response = await axios(requestData);
    return JSON.parse(JSON.stringify(response.data));
  },
};

export default QuestionTypeService;

