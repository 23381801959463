<template>
    <button class="button-with-icon" :style="{ backgroundColor: question.backgroundcolorhex }" @click="handleClick">
      <img :src="require(`@/assets/${question.icon.filename}`)" alt="Icon" class="button-icon"/>
      <span>{{question.text}}</span>
    </button>
  </template>
  
  <script>
    export default {
      name: 'ButtonQuery',
      props:{
          question: {type: Object}
      },
      data() {
        return {
        };
    },
    methods: {
      handleClick() {
        this.$emit('button-clicked', this.question);
      },
    },  
    }
  </script>

  <style scoped>
  .button-with-icon {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: #404040;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 8px; /* Adjust for desired roundness */
    width: 220px; /* Fixed width */
    height: 80px; /* Fixed height */
    margin: 20px; /* Adds margin around the button */
  }
  
  .button-icon {
    margin-right: 10px;
    width: 60px; /* Adjust based on your icon size */
    height: 60px; /* Adjust based on your icon size */
  }
  </style>