<template>

    <div class="mt-5 row">
        <div v-if="template !== null">
            <template v-if="template.id === 1">
                <DiggMinSkoleMal :statistics="statistics" :template="template"></DiggMinSkoleMal>
            </template>
        </div>

    </div>
  </template>

  <script>

    import DiggMinSkoleMal from '@/components/actiontemplates/DiggMinSkoleMal';

    export default {
      name: 'TextView',
      components:{
        DiggMinSkoleMal
      },
      props:{
        statistics: {type: Object},
        template: {type: Object},
      },
    }
    </script>
  
    <style>
  
    </style>