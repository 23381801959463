<template>
    <div class="modal fade" id="qrcodeModal" tabindex="-1" aria-labelledby="roomLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{$t('qrcode')}}</h5>
                </div>
                <div id="printModal" class="modal-body">
                    <img alt="IDGG MIN SKOLE logo" class="img-fluid" src="../assets/Dms_logo_2.png">
                    <h2 class="mb-2">https://is.nilu.no</h2>
                    <p class="font-weight-bold">{{survey.title}}</p>
                    <h4>{{survey.building}} </h4>
                    <h2 class="mb-5">{{survey.room}}</h2>
                    <h2>{{survey.publicid}}</h2>
                    <qrcode-vue :value="value" :size="size" level="H" />
                </div>
                <div class="modal-footer">
                    <button class="btn btn-success" v-print="'#printModal'">{{$t('print')}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import QrcodeVue from 'qrcode.vue'
import print from 'vue3-print-nb'
import { Modal } from 'bootstrap'

    export default {
        name: 'QRCodeModal',
        data() {
          return {
            value: 'https://is.nilu.no/',
            size: 200,
            survey: {
                title: '', 
                questionnaire :'', 
                building:'', 
                room:'',
                publicid: ''
            }
          };
        },
        components: {
            QrcodeVue,
        },
        directives: {
            print   
        },
        mounted() {
            var vm = this;
            var qrcodeModal = document.getElementById('qrcodeModal');
            qrcodeModal.addEventListener('shown.bs.modal', function () {
                var button = event.relatedTarget;
                // Extract info from data-bs-* attributes
                vm.survey.title  = button.getAttribute('data-bs-title');
                vm.survey.questionnaire = button.getAttribute('data-bs-questionnaire');
                vm.survey.building = button.getAttribute('data-bs-building');
                vm.survey.room = button.getAttribute('data-bs-room');
                vm.survey.publicid = button.getAttribute('data-bs-url');

                vm.value = 'https://is.nilu.no/response?id=' + button.getAttribute('data-bs-url');

            });
        },

        methods: {
        }
        
    }
</script>
                    