<template>
  <QuestionInfoModal></QuestionInfoModal>
  <div style="display: contents"> <!-- rendered invisible -->
    
      <div style="display: contents">
        
        <!--Only for small screens-->
        <div class=" rounded d-lg-none d-md-none mb-3" style="background-color: #F9F0F0;"> 
          <td rowspan="2" class="ps-3 pt-2 pb-2 align-middle">
            <img class="titleicon" alt="DIGG MIN SKOLE logo" :src="require(`@/assets/${question.icon.filename}`)">
          </td>
          <td class="pt-2 pb-2 text-start" style="padding-left: 30px !important" colspan="4">
            <span class="text-uppercase qtext">{{ question.text}}</span>
            <button v-if="question.showinfo" class="align-self-end btnemoji" data-bs-toggle="modal" 
              data-bs-target="#questionInfoModal" :data-bs-text="question.infotext">
              <font-awesome-icon icon="d-flex align-items-bottom fa-circle-info" :style="{ color: '#adb5bd' }" />
            </button>
            
            <button v-if="hasSpeech" class="btnemoji" @click="onTextToSpeech">
              <font-awesome-icon icon="fa-volume-high" :style="{ color: 'gray' }" />
            </button>
          </td> 
          <!-- Two rows for small devices -->
          <tr class="d-lg-none d-md-none">
            <td colspan="4" class="d-flex mb-3" style="padding-left: 30px !important">
              <button class="d-flex align-items-center btnemoji"  @click="onClick" id="verybad">
                <font-awesome-icon icon="fa-solid fa-face-frown" class="fa qicon" :class="{'faded' : verybadfade , 'verybadselected' : selected.verybad, 'verybad' : !selected.verybad}" />
              </button>
              <button class="ps-3 d-flex align-items-center btnemoji"  @click="onClick" id="bad">
                <font-awesome-icon icon="fa-solid fa-face-frown-open" class="fa qicon" :class="{'faded' : badfade , 'badselected' : selected.bad, 'bad' : !selected.bad}" />
              </button>
              <button class="ps-3 d-flex align-items-center btnemoji" @click="onClick" id="good">
                <font-awesome-icon id="smileface" icon="fa-solid fa-face-smile" :class="{'faded' : goodfade , 'goodselected' : selected.good, 'good' : !selected.good}" class="fa qicon"  />
              </button>
              <button class="ps-3 d-flex align-items-center btnemoji"  @click="onClick" id="verygood">
                <font-awesome-icon icon="fa-solid fa-face-laugh" class="fa qicon" :class="{'faded' : verygoodfade , 'verygoodselected' : selected.verygood, 'verygood' : !selected.verygood}" />
              </button>
            </td>
          </tr>
        </div>

        
        <!--Only for large and medium screens-->
          <td class="d-none d-lg-table-cell d-md-table-cell pt-2 pb-2 text-start" v-if="hasSpeech">
            <button class=" align-items-center btnemoji" @click="onTextToSpeech">
              <font-awesome-icon icon="fa-volume-high" :style="{ color: 'gray' }" />
            </button>
          </td>
          <td valign="bottom" class="d-none d-lg-table-cell d-md-table-cell pt-2 pb-2  text-endalign-items-center">
            <img class="titleicon" alt="DIGG MIN SKOLE logo" :src="require(`@/assets/${question.icon.filename}`)">
          </td>
          <td class="d-none d-lg-table-cell d-md-table-cell pt-2 pb-2 text-start" style="padding-left: 30px !important">
            <span class="text-uppercase qtext">{{ question.text}}</span>
            <button v-if="question.showinfo" class="align-self-end btnemoji" data-bs-toggle="modal" 
              data-bs-target="#questionInfoModal" :data-bs-text="question.infotext">
              <font-awesome-icon icon="d-flex align-items-bottom fa-circle-info" :style="{ color: '#adb5bd' }" />
            </button>
          </td>
          <td class="d-none d-lg-table-cell d-md-table-cell pt-2 pb-2">
            <button class="d-flex align-items-center btnemoji" @click="onClick" id="verybad">
              <font-awesome-icon icon="fa-solid fa-face-frown" :class="{'faded' : verybadfade , 'verybadselected' : selected.verybad, 'verybad' : !selected.verybad}" class="fa qicon"  />
            </button>
          </td>
          <td class="d-none d-lg-table-cell d-md-table-cell pt-2 pb-2">
            <button class="d-flex align-items-center btnemoji" @click="onClick" id="bad">
              <font-awesome-icon icon="fa-solid fa-face-frown-open" :class="{'faded' : badfade , 'badselected' : selected.bad, 'bad' : !selected.bad}" class="fa qicon" />
            </button>
          </td>
          <td class="d-none d-lg-table-cell d-md-table-cell pt-2 pb-2">
            <button class="d-flex align-items-center btnemoji" @click="onClick" id="good">
              <font-awesome-icon id="smileface" icon="fa-solid fa-face-smile" :class="{'faded' : goodfade , 'goodselected' : selected.good, 'good' : !selected.good}" class="fa qicon" />
            </button>
          </td>
          <td class="d-none d-lg-table-cell d-md-table-cell pt-2 pb-2">
              <button class="d-flex align-items-center btnemoji" @click="onClick" id="verygood">
                <font-awesome-icon icon="fa-face-laugh" swap-opacity :class="{'faded' : verygoodfade , 'verygoodselected' : selected.verygood, 'verygood' : !selected.verygood}" class="fa qicon"  />
              </button>
          </td>
      </div>
      <tr>
        <td colspan="7" class="d-none d-lg-table-cell d-md-table-cell"><hr class="mt-2 mb-2"></td>
      </tr>
  </div>

</template>

<script>

    import router from '@/router';
    import QuestionInfoModal  from "@/components/QuestionInfoModal";
    import {SpeechService} from '@/services/SpeechService'

    export default {
      name: 'SmileyQuery',
      props:{
          question: {type: Object}
      },
      data() {
          return { 
            selected: {
              verygood: false,
              good: false, 
              bad: false, 
              verybad: false
            },
          }
      },
      components: {
        QuestionInfoModal
      },
      mounted() {
      },
      methods: {
          onClick: function(event){
              var t = event.currentTarget.id;
              
              //Reset style
              this.selected.verygood = this.selected.good = this.selected.bad = this.selected.verybad = false;

              //default values. SmileyQuery only supports numeric values
              this.question.booleanvalue = null;
              this.question.textvalue = null;

              if(t === "verygood"){ 
                  this.selected.verygood = !this.selected.verygood;
                  this.question.numericvalue = 1;
                  this.question.showsubanswer = false;
              }
              if(t === "good"){ 
                  this.selected.good = !this.selected.good;
                  this.question.numericvalue = 2;
                  this.question.showsubanswer = false;
              }
              else if(t === "bad"){ 
                  this.selected.bad = !this.selected.bad;
                  this.question.numericvalue = 3;
                  this.question.showsubanswer = this.question.type.addsubquestions;
              }
              else if(t === "verybad"){ 
                  this.selected.verybad = !this.selected.verybad;
                  this.question.numericvalue = 4;
                  this.question.showsubanswer = this.question.type.addsubquestions;
              }
          },
           onTextToSpeech : async function(event) {
            if (this.hasSpeech) {
              var text = this.question.text;
              await SpeechService.speak(this.$t('audiolanguagedescription'), text);

              }
            },

        },
        computed: {
          hasSpeech: function(){
            if ('speechSynthesis' in window) {
              return true;
            }
            return false;
          },
          nonselected: function(){
            //non selected yet
            if(this.selected.verygood === false && this.selected.good === false && this.selected.bad === false && this.selected.verybad === false ){
              return true;
            }
            return false;
          },     
          verygoodfade: function(){
            //non selected yet
            if(this.nonselected){
              return false;
            }

            if(this.selected.verygood === false){
              return true;
            }
            else
              return false;
          },
          goodfade: function(){
            //non selected yet
            if(this.nonselected){
              return false;
            }

            if(this.selected.good === false){
              return true;
            }
            else
              return false;
          },
          badfade: function(){
            //non selected yet
            if(this.nonselected){
              return false;
            }

            if(this.selected.bad === false){
              return true;
            }
            else
              return false;
          },  
          verybadfade: function(){
            //non selected yet
            if(this.nonselected){
              return false;
            }

            if(this.selected.verybad === false){
              return true;
            }
            else
              return false;
          },               
      },
      }
  </script>

  <style scoped>
    .fa{
      background: radial-gradient(black 50%, transparent 50%);
    }
    .btnemoji{
      background-color: transparent;
      border-style: none;
    }
    .verygood{
      color: #719541;
    }
    .good{
      color: #A1D55D ;
    }
    .bad{
      color: #D5A55D;
    }
    .verybad{
      color: #D5735D;
    }
    .verygoodselected{
      color: #719541 !important ;
    }
    .goodselected{
      color: #A1D55D !important ;
    }
    .badselected{
      color: #D5A55D !important ;
    }
    .verybadselected{
      color: #D5735D !important ;
    }
    .faded {
      opacity: 0.3;
    }

        /* Extra small devices (phones, 600px and down) */
        @media only screen and (max-width: 600px) {
          .titleicon{
            height: 40px; 
            width: 40px;
          }
          .qcol
          {
            display: flex !important;
            align-items: center !important;
          }
          .qtext{
            font-size: 12px;
          }
          .qicon{
            height: 25px; 
            width: 25px;
          }
        }

        /* Small devices (portrait tablets and large phones, 600px and up) */
        @media only screen and (min-width: 600px) {
          .titleicon{
            height: 40px; 
            width: 40px;
          }
          .qcol
          {
            display: flex !important;
            align-items: center !important;
          }
          .qtext{
            font-size: 12px;
          }
          .qicon{
            height: 25px; 
            width: 25px
          }
        }

        /* Medium devices (landscape tablets, 768px and up) */
        @media only screen and (min-width: 768px) {
          .titleicon{
            height: 50px; 
            width: 50px;
          }
          .qcol
          {
            display: flex !important;
            align-items: flex-end !important;
          }
          .qtext{
            font-size: 24px;
          }
          .qicon{
            height: 40px; 
            width: 40px
          }
        }

        /* Large devices (laptops/desktops, 992px and up) */
        @media only screen and (min-width: 992px) {
          .titleicon{
            height: 50px; 
            width: 50px;
          }
          .qcol
          {
            display: flex !important;
            align-items: flex-end !important;
          }
          .qtext{
            font-size: 24px;
          }
          .qicon{
            height: 40px; 
            width: 40px
          }
        }

        /* Extra large devices (large laptops and desktops, 1200px and up) */
        @media only screen and (min-width: 1200px) {
          .titleicon{
            height: 50px; 
            width: 50px;
          }
          .qcol
          {
            display: flex !important;
            align-items: flex-end !important;
          }
          .qtext{
            font-size: 24px;
          }
          .qicon{
            height: 40px; 
            width: 40px
          }
          .smile{
            color: #8DCC3B ;
          }
          .smileselected{
            color: gray ;
          }
        }
  </style>