import axios from "axios";
import ErrorParser from '@/services/error.parser';
import { useAuthStore } from '@/stores/auth.store';

const QuestionnaireService = {
  store: async function (questionnaire) {
    const requestData = {
      method: "post",
      url: "/api/questionnaire",
      data: questionnaire,
      headers: {
        "authorization": "Bearer " + useAuthStore().user.jwt  
        } 
    };

    const response = await axios(requestData);
    return JSON.parse(JSON.stringify(response.data));
  },

  update: async function (questionnaire) {
    const requestData = {
      method: "put",
      url: "/api/questionnaire/" + questionnaire.id,
      data: questionnaire,
      headers: {
        "authorization": "Bearer " + useAuthStore().user.jwt  
        } 
    };

    const response = await axios(requestData);
    return JSON.parse(JSON.stringify(response.data));
  },

  questionnaires: async function () {

    const requestData = {
        method: "get",
        url: "/api/questionnaire",
        headers: {
            "authorization": "Bearer " + useAuthStore().user.jwt 
        }
      };

    const response = await axios(requestData);
    return JSON.parse(JSON.stringify(response.data));

  },
  questionnaire: async function (id) {

    const requestData = {
        method: "get",
        url: "/api/questionnaire/" + id,
        headers: {
            "authorization": "Bearer " + useAuthStore().user.jwt 
        }
      };

    const response = await axios(requestData);
    return JSON.parse(JSON.stringify(response.data));

  },
  questionnairesurvey: async function (id) {

    const requestData = {
        method: "get",
        url: "/api/questionnaire/survey/" + id
      };

    const response = await axios(requestData);
    return JSON.parse(JSON.stringify(response.data));

  },
  questionnairesurveywithpid: async function (id, nowhour, dayofweek) {

    const requestData = {
        method: "get",
        url: `/api/questionnaire/survey/publicid/${id}/${nowhour}/${dayofweek}` 
      };

    const response = await axios(requestData);
    return JSON.parse(JSON.stringify(response.data));

  },
};

export default QuestionnaireService;

