

<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <div class="col-3"></div>
      <div class="col-9 py-3">
          <div class="row">
            <div class="col-4 mt-5">
              <a class="nav-link" href="/dashboard">
                <div class="card">
                  <div class="card-body">
                    <i class="fs-1"><font-awesome-icon icon="fa-dashboard" /></i> 
                    <h5 class="card-title">{{ $t("dashboard") }}</h5>
                    <p class="card-text">{{ $t("dashboardcardinfo") }}</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-4 mt-5">
              <a class="nav-link" href="/survey">
                <div class="card">
                  <div class="card-body">
                    <i class="fs-1"><font-awesome-icon icon="fa-square-poll-vertical" /></i> 
                    <h5 class="card-title">{{ $t("newsurvey") }}</h5>
                    <p class="card-text">{{ $t("surveycardinfo") }}</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-4 mt-5"></div>
            <div class="col-4 mt-5">
              <a class="nav-link" href="/mysurveys">
                <div class="card">
                  <div class="card-body">
                    <i class="fs-1"><font-awesome-icon icon="fa-user" /></i> 
                    <h5 class="card-title">{{ $t("mysurveys") }}</h5>
                    <p class="card-text">{{ $t("mysurveyscardinfo") }}</p>
                  </div>
                </div>
              </a>
            </div>
          </div>

      </div>
    </div>
  </div>
</template>
  
  <script>
  
  import Menu from "@/components/Menu";

  export default {
    name: 'Admin',
    components: {
      Menu
    },
  }
  </script>
  
  <style>

  </style>
  