<!-- Update this component to support different types of questions -->

<template>
  <tbody>

    <!-- Template for question type 4 Smileys. Type = 22 with subquestions and 35 without subquestions -->
    <SmileyQuery :question="query" v-if="query.type.id==22"></SmileyQuery>
    <SmileyQuery :question="query" v-if="query.type.id==35"></SmileyQuery>

    <!-- Template for question type 7 graded slider with warm cold icons Type = 32-->
    <SliderQuery :question="query"  v-if="query.type.id==32"></SliderQuery>

    <!-- Template for question type 38 Button with icon, the click event is captured and emited -->

    <ButtonQuery @button-clicked="handleButtonClicked" :question="query" v-if="query.type.id==38 "></ButtonQuery>


  </tbody>

</template>

<script>

  import SmileyQuery from '@/components/survey/SmileyQuery'
  import SliderQuery from '@/components/survey/SliderQuery'
  import ButtonQuery from '@/components/survey/ButtonQuery'

  export default {
    name: 'Query',
    components:{
      SmileyQuery,
      SliderQuery,
      ButtonQuery
    },
    props:{
        query: {type: Object}
    },  
    methods: {
      handleButtonClicked(question) {
        this.$emit('query-button-clicked', question); // Forwarding the event to the parent
      },
    },
  }
  </script>

