<template>

    <div class="row" v-if="actionlist.length!==0">
        <span><h3>{{$t('actioninfo1')}}</h3></span>
        <small>( {{$t('actioninfo2')}} {{template.title}})</small>
        <small>( {{$t('actioninfo3')}} ) </small>
        <small> {{temptext}} </small>
        <span class="mt-5" v-for="action in actionlist">
            {{action.subquestion.title}}
            <br/>
            {{action.subquestion.text}}
        </span>
    </div>
  </template>

<script>
import subqactions from './diggminskole.json'
import Notify from "@/helpers/notify";
export default {
    name: 'DiggMinSkoleMal',
    components:{
    },
    props:{
        statistics: {type: Object},
        template: {type: Object},
    },
    data() {
        return {
          subqactions,
          actionlist: [],
          temptext: "",
          values:{
            positive:{
                withexception: 0,
                withoutexception: 0
            },
            negative:{
                withexception: 0,
                withoutexception: 0
            }
          },
          questionmostanswered:{
            negative:{
                firstquestion: {},
                secondquestion: {}
            }
          }
        };
    },
    watch: { 
        statistics: function(newVal, oldVal) { // watch it
            this.createTextStatistics();
        },
    },
    mounted() {
        this.temptext = "Tester text";
        this.loadInitialData();
    },

    methods: {
        async loadInitialData() {
            if(this.statistics.questionresponses !== undefined){
                this.createTextStatistics();
            }
        },
        createTextStatistics(){

            
            this.calculateValues();
            this.findMostAnsweredQuestions();

            if(this.percentPosWithoutExcpetion >= 75){
                this.actionlist.push({ subquestion: {id:0, questionid:0, title:"", text: "Mer enn 75% av alt er lysegrønt/mørkegrønt"}});
            }
            else if(this.percentPosWithExcpetion >= 75){
                this.actionlist.push({  subquestion: {id:0, questionid:0, title:"", text: "Mer enn 75% av alt (utenom min helse) er lysegrønt/mørkegrønt"}});
            }
            else if(this.percentPosWithExcpetion < 75 && this.percentPosWithExcpetion > 60){
                this.actionlist.push({  subquestion: {id:0, questionid:0, title:"", text: "Mellom 75 og 60 % (utenom min helse) er lysegrønt/mørkegrønt"}});
            }
            
            else{
                if(this.percentNegWithExcpetion > 20){
                    if(this.questionmostanswered.negative.firstquestion.question != undefined){
                        //Get all actions for all subquestions
                        var subquestions = this.subqactions.filter(sa => sa.subquestion.questionid == this.questionmostanswered.negative.firstquestion.question.id);
                        //pick random and add in list
                        if(subquestions.length > 0){
                            var randomaction = subquestions[subquestions.length * Math.random() | 0];
                            this.actionlist.push(randomaction);
                        }
                    }
                    if(this.questionmostanswered.negative.secondquestion.question != undefined){
                        //Get all actions for all subquestions
                        var subquestions = this.subqactions.filter(sa => sa.subquestion.questionid == this.questionmostanswered.negative.secondquestion.question.id);
                        //pick random and add in list
                        if(subquestions.length > 0){
                            var randomaction = subquestions[subquestions.length * Math.random() | 0];
                            this.actionlist.push(randomaction);
                        }
                    }

                    this.findActionsSubquestions();
                    this.removeActionsDuplicates();
                }
            }
            
        },
        removeActionsDuplicates()
        {
            let result = this.actionlist.filter(
                (action, index) => index === this.actionlist.findIndex(
                    other => action.subquestion.id === other.subquestion.id && action.subquestion.title === other.subquestion.title //title is used in addition because temperature has two actions cold and hot, separated with title
                ));
                this.actionlist = result;
        },
        findActionsSubquestions()
        {
            this.statistics.questionresponses.forEach(qr => {

                //sum red answers
                var totalanswers = 0;
                qr.numericcount.forEach(num => {
                    if(num.value !== 0){
                        totalanswers += num.count;
                    }
                });

                qr.subquestionresponses.forEach(sqr => {
                    var sumselected = 0;
                    var coldtempselected = 0;
                    var hottempselected = 0;

                    sqr.booleancount.forEach(b => {
                        if(b.value === true){
                            sumselected += b.count;
                        }
                    });

                    //Get subquestion action
                    var subaction = this.subqactions.find(sqa => sqa.subquestion.id === sqr.subquestion.id );

                    var percentageselected = (sumselected / totalanswers) * 100;

                    if(percentageselected >= subaction.subquestion.treshold ){
                        //subaction.title = sqr.subquestion.text;
                        this.actionlist.push(subaction);
                    }

                    //Special case: temperature, this one will have two actions : one for cold and one for hot temp based on the two coldest/hotest values
                    if(sqr.subquestion.id===54){
                        sqr.numericcount.forEach(n => {
                            if(n.value === 1 || n.value === 2){
                                coldtempselected += n.count;
                            }
                            else if(n.value === 6 || n.value === 7){
                                hottempselected += n.count;
                            }
                        });

                        var coldpercentageselected = (coldtempselected / totalanswers) * 100;
                        var hotpercentageselected = (hottempselected / totalanswers) * 100;
                        var coldsubaction = this.subqactions.find(sqa => sqa.subquestion.id === sqr.subquestion.id &&  sqa.subquestion.title === 'KALDT' );
                        var hotsubaction = this.subqactions.find(sqa => sqa.subquestion.id === sqr.subquestion.id &&  sqa.subquestion.title === 'VARMT' );

                        if(coldpercentageselected >= coldsubaction.subquestion.treshold ){
                            this.actionlist.push(coldsubaction);
                        }
                        if(hotpercentageselected >= hotsubaction.subquestion.treshold ){
                            this.actionlist.push(hotsubaction);
                        }
                    }

                });
            });
        },
        calculateValues()
        {
            //Nullify
            this.nullifyvalues();
            
            this.statistics.questionresponses.forEach(qr => {
            
                qr.numericcount.forEach(num => {
                    //Positive
                    if(num.value === 1 || num.value === 2){
                        this.values.positive.withoutexception += num.count;
                        if(qr.question.id !== 49){ //Except Min helse
                            this.values.positive.withexception += num.count;
                        }
                    }
                    //Negative
                    else if(num.value === 3 || num.value === 4){
                        this.values.negative.withoutexception += num.count;
                        if(qr.question.id !== 49){ //Except Min helse
                            this.values.negative.withexception += num.count;
                        }
                    }
                });
            });

        },
        nullifyvalues(){
            this.values.positive.withexception = 0;
            this.values.positive.withoutexception = 0;
            this.values.negative.withexception = 0;
            this.values.negative.withoutexception = 0;
        },

        findMostAnsweredQuestions(){

            var mostanswered = [];
            this.statistics.questionresponses.forEach(qr => {

                var sortednegative = qr.numericcount.filter(function (s){return s.value > 2});
                if(sortednegative.length > 0){
                    //Create object, sum the number of answers and keep the question object
                    var obj = {
                        count: sortednegative.reduce((n, {count}) => n + count, 0),
                        question: qr.question
                    }
                    mostanswered.push(obj);
                }
            });

            if(mostanswered.length > 0 ){
                var sorted = mostanswered.sort(({count:a}, {count:b}) => b-a);
                this.questionmostanswered.negative.firstquestion = sorted[0];
                if(mostanswered.length >= 2){
                    this.questionmostanswered.negative.secondquestion = sorted[1];
                }
            }

        }

    },
    computed: {
        totalWithException: function(){
            return this.values.positive.withexception + this.values.negative.withexception;
        },
        totalWithoutException: function(){
            return this.values.positive.withoutexception + this.values.negative.withoutexception;
        },
        percentPosWithExcpetion: function(){
            return (this.values.positive.withexception / this.totalWithException ) * 100;
        },
        percentPosWithoutExcpetion: function(){
            return (this.values.positive.withoutexception / this.totalWithoutException ) * 100;
        },
        percentNegWithExcpetion: function(){
            return (this.values.negative.withexception / this.totalWithException ) * 100;
        },
        percentNegWithoutExcpetion: function(){
            return (this.values.negative.withoutexception / this.totalWithoutException ) * 100;
        },
    
    },  
}
</script>
  
    <style>
  
    </style>