<template>
        <div class="mb-4 col-6">
              <label class="mb-3 ">{{questionresponse.question.text}}</label>
              <div style="max-height: 444px;" class="text-start">
                
                <apexchart
                    width="400"
                    type="pie"
                    :options="piechartOptions"
                    :series="pieseries.length > 0 ? pieseries : []"
                    :key="pieseries.length"
                    ></apexchart>
                
            </div>
        </div>
</template>

<script>
import ApexchartHelper from "@/helpers/settings/apexcharts";
import VueApexCharts from "vue3-apexcharts";
import { h } from 'vue'

export default {
    name: 'StatPieChart',
    props:{
        questionresponse:{type: Object},
        noresponses: {type: Number},
    },
    data() {
      return {
        piechartOptions: ApexchartHelper.piesettings(this.questionresponse.question.id, true),
        pieseries: [],
      };
    },
    components: {
        apexchart: VueApexCharts,
        ApexchartHelper
    },
    beforeMount() {
        this.setPiechartSeries();
    },
    methods: {
        async setPiechartSeries(){

            this.piechartOptions.labels =  [this.$t('goodlabel'), this.$t('mediumlabel'), this.$t('badlabel'), this.$t('verybadlabel'), this.$t('norespons')]; 
            const ps = [];

            //First 0, then 1, then 2, then 3
            var t0 = this.questionresponse.numericcount.filter(({value}) => value === 1).reduce((accumulator, object) => {return accumulator + object.count;}, 0);
            ps.push(t0)
            var t1 = this.questionresponse.numericcount.filter(({value}) => value === 2).reduce((accumulator, object) => {return accumulator + object.count;}, 0);
            ps.push(t1)
            var t2 = this.questionresponse.numericcount.filter(({value}) => value === 3).reduce((accumulator, object) => {return accumulator + object.count;}, 0);
            ps.push(t2)
            var t3 = this.questionresponse.numericcount.filter(({value}) => value === 4).reduce((accumulator, object) => {return accumulator + object.count;}, 0);
            ps.push(t3)

            //Calculate number of missing responsen
            var sum = t0 + t1 + t2 + t3;
            var diff = this.noresponses - sum;
            ps.push(diff)

            this.pieseries = ps;
        },
    }
    
}
</script>

<style>

</style>
    