import Vue, {createApp} from 'vue'
import i18n from '../../services/i18n'
import App from './App.vue'

//Vue.configureCompat({ WATCH_ARRAY: false, RENDER_FUNCTION: false })

import router from '@/router'
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap";
// Importing the global css file
import "@/assets/global.css" 
import { createPinia, storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth.store';
import Notifications from '@kyvg/vue3-notification'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faArrowRight, faFaceSmile, faFaceMeh, faFaceFrown, faHouse, faDashboard, faUser, faWrench, faSquarePollVertical, faDrumSteelpan, 
    faIcons, faArrowsRotate, faUsers, faCheckCircle, faFaceLaugh, faFaceFrownOpen, faVolumeHigh, faQrcode, faBuilding, faDoorOpen, faIdCard, 
    faKey, faFileInvoice, faPaperclip, faNewspaper, faClipboardQuestion, faCircleInfo, faArrowLeft, faCheckSquare, faStar, faLightbulb, faTimes, faTrash, faPlus  } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faArrowRight)
library.add(faFaceSmile)
library.add(faFaceMeh)
library.add(faFaceFrown)
library.add(faHouse)
library.add(faDashboard)
library.add(faUser)
library.add(faWrench)
library.add(faSquarePollVertical)
library.add(faDrumSteelpan)
library.add(faIcons)
library.add(faArrowsRotate)
library.add(faUsers)
library.add(faCheckCircle)
library.add(faFaceLaugh)
library.add(faFaceFrownOpen)
library.add(faVolumeHigh)
library.add(faQrcode)
library.add(faBuilding)
library.add(faDoorOpen)
library.add(faIdCard)
library.add(faKey)
library.add(faFileInvoice)
library.add(faPaperclip)
library.add(faNewspaper)
library.add(faClipboardQuestion)
library.add(faCircleInfo)
library.add(faArrowLeft)
library.add(faCheckSquare)
library.add(faStar)
library.add(faLightbulb)
library.add(faTimes)
library.add(faTrash)
library.add(faPlus)

const app = createApp(App)

app.provide('$storeToRefs', storeToRefs); //globaly available
app.provide('$useAuthStore', useAuthStore); //globaly available

app.use(i18n);
app.use(createPinia());
app.use(router);
app.use(Notifications);

app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app');
