<template>
    <div style="display: contents"> <!-- rendered invisible -->
        <QuestionInfoModal></QuestionInfoModal>
          <div style="display: contents">
            
            <!--Only for small screens-->
            <div class=" rounded d-lg-none d-md-none mb-3" style="background-color: #F9F0F0;"> 
                <tr>
                    <td rowspan="2" class="ps-3 pt-2 pb-2 align-middle">
                        <img class="titleicon" alt="DIGG MIN SKOLE logo" :src="require(`@/assets/${question.icon.filename}`)">
                    </td>
                    <td class="pt-2 pb-2 text-start w-100" style="padding-left: 30px !important">
                        <span class="text-uppercase qtext">{{ question.text}}</span>
                        <button v-if="question.showinfo" class="align-self-end btnemoji" data-bs-toggle="modal" 
                          data-bs-target="#questionInfoModal" :data-bs-text="question.infotext">
                          <font-awesome-icon icon="d-flex align-items-bottom fa-circle-info" :style="{ color: '#adb5bd' }" />
                        </button>
                        <button v-if="hasSpeech" class="btnemoji" @click="onTextToSpeech">
                          <font-awesome-icon icon="fa-volume-high" :style="{ color: 'gray' }" />
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>
                        <!--Default is smiley with color set on type-->
                        <div class="mb-3 ms-3 me-3 d-flex align-items-center">            
                            <font-awesome-icon v-if="question.type.endicon == null" icon="fa-solid fa-face-frown fa-8x" class="fa slidericon" :style="{ color: question.type.hexend }" />
                            <img v-else class="slidericon"  alt="IDGG MIN SKOLE logo" :src="require(`@/assets/${question.type.endicon.filename}`)">
                            <input ref="gradeslidersm" type="range" :min="question.type.minint" :max="question.type.maxint" v-model="slidervalue" :step="question.type.intstep" class="slider" id="lslider" @change="sliderchange">
                            <!--Default is smiley with color set on type-->
                            <font-awesome-icon v-if="question.type.starticon == null" icon="fa-solid fa-face-smile " class="fa slidericon " 
                            :style="{ color: question.type.hexstart }" />
                            <img v-else class="slidericon"  alt="IDGG MIN SKOLE logo" :src="require(`@/assets/${question.type.starticon.filename}`)">
                        </div>
                    </td>
                </tr>
            </div>
    
            
            <!--Only for large and medium screens-->
              <td class="d-none d-lg-table-cell d-md-table-cell pt-2 pb-2 text-start" v-if="hasSpeech">
                <button class=" align-items-center btnemoji" @click="onTextToSpeech">
                  <font-awesome-icon icon="fa-volume-high" :style="{ color: 'gray' }" />
                </button>
              </td>
              <td valign="bottom" class="d-none d-lg-table-cell d-md-table-cell pt-2 pb-2  text-endalign-items-center">
                <img class="titleicon" alt="DIGG MIN SKOLE logo" :src="require(`@/assets/${question.icon.filename}`)">
              </td>
              <td class="d-none d-lg-table-cell d-md-table-cell pt-2 pb-2 text-start" style="padding-left: 30px !important">
                <span class="text-uppercase qtext">{{ question.text}}</span>
                <button v-if="question.showinfo" class="align-self-end btnemoji" data-bs-toggle="modal" 
                  data-bs-target="#questionInfoModal" :data-bs-text="question.infotext">
                  <font-awesome-icon icon="d-flex align-items-bottom fa-circle-info" :style="{ color: '#adb5bd' }" />
                </button>
              </td>
              <td colspan="4" class="d-none d-lg-table-cell d-md-table-cell pt-2 pb-2 text-start">
                <!--Default is smiley with color set on type-->
                <div class="d-flex align-items-center">            
                    <font-awesome-icon v-if="question.type.endicon == null" icon="fa-solid fa-face-frown fa-8x" class="fa slidericon" :style="{ color: question.type.hexend }" />
                    <img v-else class="titleicon"  alt="IDGG MIN SKOLE logo" :src="require(`@/assets/${question.type.endicon.filename}`)">
                    <input ref="gradeslider" type="range" :min="question.type.minint" :max="question.type.maxint" v-model="slidervalue" :step="question.type.intstep" class="slider" id="lslider" @change="sliderchange">
                    <!--Default is smiley with color set on type-->
                    <font-awesome-icon v-if="question.type.starticon == null" icon="fa-solid fa-face-smile " class="fa slidericon " 
                    :style="{ color: question.type.hexstart }" />
                    <img v-else class="titleicon"  alt="IDGG MIN SKOLE logo" :src="require(`@/assets/${question.type.starticon.filename}`)">
                </div>
            </td>
          </div>
          <tr>
            <td colspan="7" class="d-none d-lg-table-cell d-md-table-cell"><hr class="mt-2 mb-2"></td>
          </tr>
      </div>

</template>

<script>

    import QuestionInfoModal  from "@/components/QuestionInfoModal";
    import router from '@/router';

    export default {
      name: "LSlider",
      props: [
        "question"
      ],
      data: function () {
        return {
            slidervalue : { type: Number }
        }
      },
      components: {
        QuestionInfoModal
      },
      mounted() {

            //Set colors:
            if(this.question.type.hexsteps){
                //Set background color
                var hexsteps = this.question.type.hexsteps.split(',');
                var reversedhexsteps = hexsteps.reverse();


                var steps = `linear-gradient(to right, ${reversedhexsteps.toString()}` ;
                this.$refs.gradeslider.style.setProperty('--SliderBackgroundColor', steps);
                this.$refs.gradeslidersm.style.setProperty('--SliderBackgroundColor', steps);
                //Set Thumb Color according to startvalue
                this.$refs.gradeslider.style.setProperty('--SliderThumbColor', this.setThumbColor(this.question.type.intstartvalue, this.question.type.hexsteps));
                this.$refs.gradeslidersm.style.setProperty('--SliderThumbColor', this.setThumbColor(this.question.type.intstartvalue, this.question.type.hexsteps));

                //Set Thumb color when value changes (Only supporting thumbs coloring with steps 1, corresponding to the hex steps)
                if(this.question.type.intstep === 1){
                    this.$refs.gradeslider.oninput =_=> this.$refs.gradeslider.style.setProperty('--SliderThumbColor', this.setThumbColor(this.slidervalue, this.question.type.hexsteps))
                    this.$refs.gradeslidersm.oninput =_=> this.$refs.gradeslidersm.style.setProperty('--SliderThumbColor', this.setThumbColor(this.slidervalue, this.question.type.hexsteps))
                }
            }

            this.slidervalue = this.question.type.intstartvalue;
            this.$emit('lsOnClick', this.slidervalue); //update parent with default value
            
      },
      methods: {
        sliderchange : function(event) {
            var t = this.slidervalue;
            this.$emit('lsOnClick', this.slidervalue);
        },
        setThumbColor(value, steps){
            const arr = steps.split(',')
            return arr[value-1];
        },
        setSliderBackgroundColor(value){
        },
        onTextToSpeech : function(event) {
            if (this.hasSpeech) {
                var text = this.question.text;
                text = text.replace('NÅ', ', NÅ'); //Create some space before speaking the norwegian word NÅ
                const utterThis = new SpeechSynthesisUtterance(text);
                
                const synth = window.speechSynthesis;
                utterThis.rate = 0.4;
                utterThis.lang = 'nb-NO';
                synth.speak(utterThis);
            }
        },
      },
      computed: {
          hasSpeech: function(){
            if ('speechSynthesis' in window) {
              return true;
            }
            return false;
          },
      }

    };
</script>
<style lang="postcss" scoped>
.btnemoji{
    background-color: transparent;
    border-style: none;
}

.fa{
      background: radial-gradient(black 50%, transparent 50%);
    }

.slider {
    --SliderThumbColor: #cc3b3b; /*default value*/
    --SliderBackgroundColor: linear-gradient(to right, #35A834, #8DCC3B, #FEA807, #ED4E3B); /*default value*/
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    border-radius: 5px; 
    background: var(--SliderBackgroundColor);
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border: 2px solid black;
    direction: rtl
}

.slider::-webkit-slider-thumb {
  background-color: var(--SliderThumbColor);
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%; 
  cursor: pointer;
  border: 2px solid black;
}

.slider::-moz-range-thumb {
  background-color: var(--SliderThumbColor);  
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid black;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .titleicon{
        height: 40px; 
        width: 40px;
    }
    .slidericon{
        height: 15px;
        width: 15px;
    }
    .qtext{
        font-size: 12px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .titleicon{
        height: 40px; 
        width: 40px;
    }
    .slidericon{
        height: 15px;
        width: 15px;
    }
    .qtext{
        font-size: 12px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .titleicon{
        height: 30px;
        width: 30px;
    }
    .slidericon{
        height: 30px;
        width: 30px;
    }
    .qtext{
        font-size: 24px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .titleicon{
        height: 30px;
        width: 30px;
    }
    .slidericon{
        height: 30px;
        width: 30px;
    }
    .qtext{
        font-size: 24px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .titleicon{
        height: 30px;
        width: 30px;
    }
    .slidericon{
        height: 30px;
        width: 30px;
    }
    .qtext{
        font-size: 24px;
    }
}

</style>