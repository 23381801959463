<template>
    <div class="container align-items-center">
      <div class="mt-5 row text-center">
        <div class="col">
          <img class="img-fluid" alt="IDGG MIN SKOLE logo" src="../../assets/Dms_logo_2.png">
        </div>
      </div>
      <div class="mt-5 row text-center">
        <div class="col">
          <h3>{{$t('welcometitle')}}</h3> 
        </div>
      </div>
      <form @submit.prevent="startsurvey">
        <div class="row text-center">
          <div class="col mt-5">
            <input type="text" class="mt-4 text-center text-uppercase" placeholder="1YSW4M" v-model="surveyid" required>
          </div>
        </div>
        <div class="mt-3 row text-center">
          <div class="col mb-5">
            <button type="submit" class="m-1 btn btn-isprimary fw-bold">{{$t('startsurvey')}}</button>
          </div>
        </div>
      </form>

       Icons by <a target="_blank" href="https://icons8.com">Icons8</a>

    </div>
  </template>
  
  <script>
  import LButton from "@/components/LButton"
  import router from '@/router';

  export default {
    name: 'Welcome',
    components: {
        LButton
    }, 
    data() {
      return {
        surveyid:''

      };
    },
    methods: {
      async startsurvey() {
        router.push({
            path: "Response",
            query: {id: this.surveyid.trim().toUpperCase()}
        });
      },
  }

  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>

  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  img{
    height: 150px;
  }
  </style>
  