<template>
    <div>
      <div class="mt-5 row ">
        <div class="col-6 fs-5">{{survey.title}}</div>
        <div class="col-6 fs-5"> {{survey.building.text}} {{survey.room.text}}</div>
      </div>
      <div class="mt-3 row">
        <div class="col-6 border">
          <p class=" p-0 m-1 fw-bold">{{ $t("totansinperiod") }} </p>
        </div>
        <div class="col-6 border">
          <p class="p-0 m-1 fw-bold">{{ statistics.noresponses }} </p>
        </div>
      </div>
      
      <template v-if="view.overview === true">
        <OverView :statistics="statistics"></OverView>
      </template>

      <template v-if="view.detailedview === true">
        <DetailedView :statistics="statistics"></DetailedView>
      </template>
  
      <template v-if="view.textview === true">
        <TextView :statistics="statistics" :template="actiontemplate" ></TextView>
      </template>
 
      <template v-if="view.responsedistributionview === true"> 
        <ResponseDistributionView :responses="responses" ></ResponseDistributionView>
      </template>

      <template v-if="view.reportview === true"> 
        <ReportView :statistics="statistics" :responses="responses" ></ReportView>
      </template>

    </div>
  </template>
  <script>
  import StatisticsService from "@/helpers/apiservices/statistics/statistics.service";
  import ResponseService from "@/helpers/apiservices/response/response.service";
  import Notify from "@/helpers/notify";
  import DetailedView from '@/components/statistics/DetailedView';
  import OverView from '@/components/statistics/OverView';
  import TextView from '@/components/statistics/TextView';
  import ResponseDistributionView from '@/components/statistics/ResponseDistributionView';
  import ReportView from '@/components/statistics/ReportView';

  import * as moment from "moment";

    export default {
      name: 'SurveyStatistics',
      components:{
        DetailedView,
        OverView,
        TextView,
        ResponseDistributionView,
        ReportView
      },
      props:{
          survey: {type: Object},
          dateRange: {type: Object},
          view: {type: Object},
          actiontemplate: {type: Object},
      },
      data() {
        return {
          statistics: { type: Object },
          responses: []
        };
    },
      mounted() {
        Notify.loadData(null, this.loadInitialData);
        },
      methods: {
          async loadInitialData() {
              var from = moment(moment(this.dateRange.start).format('YYYY-MM-DD')).unix();
              var to = moment(moment(this.dateRange.end).format('YYYY-MM-DD')).add(23, 'hours').unix();
              this.statistics = await StatisticsService.statisticsByDate(this.survey.id, from, to);
              this.responses = await ResponseService.responsesByDate(this.survey.id, from, to);
          },
      }  
    }
    </script>
  
    <style>
  
    </style>