<template>
    <div class="container-fluid">
        <div class="row flex-nowrap">
          <div class="col-3"></div>
          <div class="mt-5 col-8 py-3">
            <div class="ms-3 text-start d-flex align-items-center">
              <i class="fs-1"><font-awesome-icon icon="fa-star" /></i>
              <h5 class="ms-2 card-title">{{ $t("actiontemplates") }}</h5>  
            </div>
            <div class="mt-3 ms-4 d-flex flex-column align-items-start text-start">
              <span>
                {{$t('actiontemplatesinfo1')}}
              </span>
              <span>
                {{$t('actiontemplatesinfo3')}}
              </span>
              <span>
                <br/>{{$t('actiontemplatesinfo2')}}
              </span>
            </div>

            <table class="mt-5 table table-striped">
              <thead>
                <tr>
                  <th scope="col" class="text-start">
                    <span>
                      <font-awesome-icon icon="fa-solid fa-newspaper"></font-awesome-icon> 
                      {{$t('title')}}
                    </span>
                  </th>
                  <th scope="col" class="text-start">
                    <span>
                      <font-awesome-icon icon="fa-file-invoice" />
                      {{$t('description')}}
                    </span>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                    <td><input type="text" class="form-control text-align-bottom" aria-label="Username" aria-describedby="basic-addon1" v-model="actiontemplateInsert.title"></td>
                    <td><textarea maxlength="500" class="form-control" rows="3" v-model="actiontemplateInsert.description"></textarea>
                    </td>
                    <td><button type="button" v-on:click="storeActionTemplate" class="btn-bottom btn btn-islink ">{{$t('add')}}</button></td>  
                </tr>
              </tbody>
            </table>

            <table class="mt-5 table table-striped">
                <thead>
                  <tr>
                    <th scope="col" class="text-start">
                      <span>
                        <font-awesome-icon icon="fa-solid fa-paperclip"></font-awesome-icon> 
                        ID
                      </span>
                    </th>
                    <th scope="col" class="text-start">
                        <span>
                          <font-awesome-icon icon="fa-file-invoice"></font-awesome-icon> 
                          {{$t('title')}}
                        </span>
                      </th>
                    <th scope="col" class="text-start">
                      <span>
                        <font-awesome-icon icon="fa-file-invoice" />
                        {{$t('description')}}
                      </span>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                    
                    <component is="ActionTemplate" :actiontemplate="qt" v-for="qt in actiontemplates" :key="qt.id"  @loadInitialData="loadInitialData" >  
                    </component>
                
                </tbody>
              </table>
          </div>
        </div>
    </div>
  
</template>

<script>

import { defineComponent } from 'vue';
import ActionTemplateService from "@/helpers/apiservices/actiontemplate/actiontemplate.service";
import Notify from "@/helpers/notify";
import ActionTemplate from '@/components/ActionTemplate';

export default {
    name: 'ActionTemplates',
    data() {
      return {
        actiontemplates:[],
        addNewActionTemplate: false,
        actiontemplateInsert: {
            title:'', 
            description:''
        }
      };
    },
    components: {
        ActionTemplate
    },
    mounted() {
        Notify.loadData(null, this.loadInitialData);
    },

    methods: {
        async loadInitialData() {
            this.actiontemplates = await ActionTemplateService.actiontemplates();
        },
        async storeActionTemplate() {
            Notify.loadData("stored", this.store);
        },
        async store(){
            await ActionTemplateService.store({id: 0, title: this.actiontemplateInsert.title, description: this.actiontemplateInsert.description});
            this.loadInitialData();
            this.addNewActionTemplate = false;
        },
    }
    
}
</script>

<style>
.btn-bottom{
    margin:0 !important
}
</style>
    