import axios from "axios";
import ErrorParser from '@/services/error.parser';
import { useAuthStore } from '@/stores/auth.store';

const RoomService = {
  rooms: async function (id) {

    const requestData = {
        method: "get",
        url: "/api/building/" + id + "/room",
        headers: {
            "authorization": "Bearer " + useAuthStore().user.jwt 
        }
      };
      const response = await axios(requestData);
      return JSON.parse(JSON.stringify(response.data));

  },
  store: async function (id, room) {
    const requestData = {
      method: "post",
      url: "/api/building/" + id + "/room",
      data: room,
      headers: {
        "authorization": "Bearer " + useAuthStore().user.jwt  
        } 
    };

    const response = await axios(requestData);
    return JSON.parse(JSON.stringify(response.data));
  }
}

export default RoomService;

