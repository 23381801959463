<template>
    <button class="l-button" @click="onClick">{{ text }}</button>
</template>

<script>
    import router from '@/router';

    export default {
      name: "LButton",
      props: {
        page : { type: String },
        text : { type: String }

      },
      methods: {
        onClick : function(event) {
            var t = this.page;
            router.push({
                name: t,
            });
        },
      }
    };
</script>
<style lang="postcss">
.l-button {
    @apply relative leading-none whitespace-no-wrap cursor-pointer bg-gray-400;
    @apply border border-gray-500 rounded text-center outline-none m-0;
    @apply text-pastel-black;

    /*box-sizing: border-box;*/
    transition: 0.1s;
    transition: all 0.3s;
    -webkit-appearance: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    background-color:#44c767;
	border-radius:21px;
	border:3px solid #18ab29;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:28px;
	padding:16px 31px;
	text-decoration:none;
	text-shadow:0px 1px 0px #2f6627;
    margin-bottom: 25px;
   }
.l-button:hover {
	background-color:#5cbf2a;
}
.l-button:active {
	position:relative;
	top:1px;
}


</style>