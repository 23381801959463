import axios from "axios";
import { useAuthStore } from '@/stores/auth.store';

const ResponseService = {

  store: async function (respons) {
    const requestData = {
      method: "post",
      url: "/api/response/",
      data: respons
    };

    const response = await axios(requestData);
    return JSON.parse(JSON.stringify(response.data));
  },

  responses: async function () {
    const requestData = {
        method: "get",
        url: "/api/response",
        headers: {
            "authorization": "Bearer " + useAuthStore().user.jwt 
        }
      };

    const response = await axios(requestData);
    return JSON.parse(JSON.stringify(response.data));
  },

  responsesByDate: async function (id, startDate, endDate) {
    const requestData = {
        method: "get",
        url: `/api/response/${id}/${startDate}/${endDate}`,
        headers: {
            "authorization": "Bearer " + useAuthStore().user.jwt 
        }
      };
    const response = await axios(requestData);
    return JSON.parse(JSON.stringify(response.data));
  },
}

export default ResponseService;

