import SpeechApiService from '@/helpers/apiservices/survey/speech.service';

export const SpeechService = {
  isProcessingSpeech: false,

  async speak(languageDescription, text) {
    if (this.isProcessingSpeech) return;
    this.isProcessingSpeech = true;

    try {
      // Use the singlemessage function directly
      const response = await SpeechApiService.singlemessage(languageDescription, text);
      if (response.status === 200) {
        const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);
        audio.play();
        await new Promise(resolve => audio.onended = resolve); // Wait for audio to finish
      } else {
        console.error('Failed to generate speech', response);
      }
    } catch (error) {
      console.error('An error occurred while generating speech', error);
    } finally {
        this.isProcessingSpeech = false;
    }
  }
};