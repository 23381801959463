<!-- CURRENTLY THIS IS HARDCODED FOR DIGG MIN SKOLE TO SHOW POSSIBLE STATISTICS FOR DISCUSSIONS -->
<template>
    <div class="mb-4 col-9">
        

        <!-- Special temperature question -->
        <template v-if="qid != 47">
            {{totSelected}}
        </template>
        <template v-else>
            <div v-if="numericcount.length < 1">{{totSelected}}</div>
            <div v-if="qid == 47 && sqid == 54">
                <div class="row border" v-for="t in temperatures">
                    <div class="col-4" v-bind:style= "{backgroundColor: t.hexcolor}">
                        {{t.value}}
                    </div>
                    <div class="col-4 text-end">
                        {{t.count}}
                    </div>
                </div>
            </div>
        </template>
        
        <!--
          <div style="max-height: 444px;" class="text-start">
            <apexchart
            width="350"
            type="bar"
            :options="barchartOptions"
            :series="barseries.length > 0 ? barseries : []"
            :key="barseries.length"
          ></apexchart>
        </div>
    -->
    </div>
</template>

<script>
import ApexchartHelper from "@/helpers/settings/apexcharts";
import VueApexCharts from "vue3-apexcharts";

export default {
name: 'StatBarChart',
props:{
    numericcount:{type: Array},
    booleancount:{type: Array},
    qid: {type: Number},
    sqid: {type: Number},

},
data() {
  return {
    barchartOptions: ApexchartHelper.barsettings(this.qid, true),
    barseries: [],
    totSelected: {type: Number},
    temperatures: [
        {value: 1, count: 0, hexcolor:'#0142FF' },
        {value: 2, count: 0, hexcolor:'#01A0FE' },
        {value: 3, count: 0, hexcolor:'#5DDDFF' },
        {value: 4, count: 0, hexcolor:'#FFFCF3' },
        {value: 5, count: 0, hexcolor:'#FED73C' },
        {value: 6, count: 0, hexcolor:'#FF9501' },
        {value: 7, count: 0, hexcolor:'#FF3300' }
    ]
  };
},
components: {
    apexchart: VueApexCharts,
    ApexchartHelper
},
beforeMount() {
    this.setbarchartSeries();
},
methods: {
    async setbarchartSeries(){

        //Spesific bar for temperature, uses numericcount on subquestion 39 (VARMT/KALDT)
        if(this.qid == 47 && this.sqid == 54){
            this.temperatures = this.updateTemperatures(this.temperatures, this.numericcount);
        }

        /*
        var sumSelected = 0;
        this.booleancount.forEach(b => {
            if(b.value === true){
                sumSelected += b.count;
            }
        });
        this.totSelected = sumSelected;
*/
        //else use boolean count
        var sumSelected = 0;
        this.booleancount.forEach(b => {
            if(b.value === true){
                sumSelected += b.count;
            }
        });
        this.totSelected = sumSelected;
        
    },
    updateTemperatures(temperatures, numericcount) {
        return temperatures.map((temperature) => {
            const count = this.numericcount.find((numCount) => numCount.value === temperature.value); 
            if (count) {
            return { ...temperature, count: count.count };
            }
            return temperature;
        });
    }
}

}
</script>

<style>

</style>
