import axios from "axios";
import { useAuthStore } from '@/stores/auth.store';

const QuestionService = {
  
  delete: async function (id) {
    const requestData = {
      method: "delete",
      url: "/api/question/" + id,
      headers: {
        "authorization": "Bearer " +  useAuthStore().user.jwt  
        } 
    };

    const response = await axios(requestData);
    return JSON.parse(JSON.stringify(response.data));

  }
};

export default QuestionService;

