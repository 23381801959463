import { defineStore } from 'pinia';
import router from '@/router'
import UserService from "@/helpers/apiservices/user/user.service"

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        returnUrl: null
    }),
    actions: {
        async login(email, password) {

            var login = {
                email: email,
                password: password
              };

            //call api to check user  
            const user = await UserService.login(login);

            // update pinia state
            this.user = user;

            // store user details and jwt in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            // redirect to previous url or default to home page
            router.push(this.returnUrl || '/admin');
        },
        async register(email, password){
            var register = {
                email: email,
                password: password
              };

            //call api to check user  
            const reguser = await UserService.register(register);

            //Auto login
            //call api to check user  
            const user = await UserService.login(register);

            // update pinia state
            this.user = user;

            // store user details and jwt in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            // redirect to previous url or default to home page
            router.push(this.returnUrl || '/admin');
        },
        logout() {
            this.user = null;
            localStorage.removeItem('user');
            router.push('/login');
        }
    }
});
