import { notify } from "@kyvg/vue3-notification"; 

const Notify = {
  loader: (loading, message) => {
    //Notify.stop();
    //var so = {
     // title: "Loading",
     // body: message ? message : "Please wait while data is loading",
     // mode: "loader"
    _//};

    //if (loading)
      //Notify.start(so);
    //else
      //Notify.stop();
  },
  error: (message) => {
    notify({
        title: "Error",
        text: message,
        duration: 2000,
        type: "error",
      });
  },
  success: (m, message) => {
    if(m)
        notify({
            title: "Success",
            text: "Successfully " + message,
            duration: 2000,
            type: "success",
        });
  },

  async loadData(m, f, ...p) {
    
    try {
      await f(...p);
      Notify.success(m !== null, m);
    } catch (e) {

      Notify.error(e.message);
    }
  }

}

export default Notify;