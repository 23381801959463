<template>

    <tr v-if="edit">
        <td class="align-middle">{{action.id}}</td>
        <td><textarea maxlength="1000" class="form-control" rows="4" v-model="action.text"></textarea></td>
        <td class="text-end"><button type="button" v-on:click="updateAction" class="m-1 btn btn-islink btn-sm fw-bold">{{$t('save')}}</button></td>  
    </tr>
    <tr v-else>
        <td class="align-middle">{{action.id}}</td>
        <td class="align-middle text-start">{{action.text}}</td>
        <td class="text-end">
            <button @click="setEdit" class="m-1 btn btn-islink btn-sm fw-bold">{{$t('edit')}}</button>
            <button @click="deleteAction" class="m-1 align-middle btn btn-islink btn-sm fw-bold link-isdanger">{{$t('delete')}}</button>
        </td>
    </tr>

</template>

<script>

import ActionService from "@/helpers/apiservices/action/action.service"
import Notify from "@/helpers/notify";

export default {
    name: 'Action',
    props:[
        'action'
    ],
    data() {
      return {
        edit: false
      };
    },
    methods: {
        async deleteAction() {
            Notify.loadData("deleted", this.delete);
        },
        async updateAction() {
            Notify.loadData("updated", this.update);
            
        },
        async delete(){
            await ActionService.delete(this.action);
            this.$emit('loadInitialData');
        },
        setEdit(){
            this.edit = !this.edit;
        },
        async update(){
            await ActionService.update(this.action);
            this.setEdit();
            this.$emit('loadInitialData');
        },

    }
    
}
</script>

<style>
    td{padding:0px !important}
</style>
    