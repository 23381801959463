<template>

    <div class="ms-5 mt-5 row">

<!--
        <template v-for="qr in statistics.questionresponses" :key="qr.question.id">
            <StatPieChart :questionresponse="qr" :noresponses="statistics.noresponses"></StatPieChart>
        </template>
    -->
        <!-- Nav tabs -->
<ul class="nav nav-tabs">
    <template v-for="(qr, index) in statistics.questionresponses" :key="qr.question.id">
        <li class="nav-item">
            <a class="nav-link" :class="{ 'active': index === 0 }" data-bs-toggle="tab" href="#" @click="setdivshowindex(index)">{{qr.question.text}}</a>
        </li>
    </template>
  </ul>
  
  <!-- Tab panes -->
  <div class="tab-content">
    <template v-for="(qr, index) in statistics.questionresponses" :key="qr.question.id">
        <div class="mt-5 row d-flex align-items-bottom" v-if="divshowindex==index">
          <div class="col-6 align-self-end ">
            <template v-for="subqresponse in qr.subquestionresponses">
              <div class="row">
                  <div class="col-8 text-start">
                      <div>{{subqresponse.subquestion.text}}</div>
                  </div>
                  <div class="col-2">
                      <div v-if="subqresponse.numericcount != null">
                          <StatBarChart :numericcount="subqresponse.numericcount" :booleancount="subqresponse.booleancount" :qid="qr.question.id" :sqid="subqresponse.subquestion.id"></StatBarChart>
                      </div>
                  </div>
              </div>
          </template>
          </div>
          <div class="col-6">
            <StatPieChart :questionresponse="qr" :noresponses="statistics.noresponses"></StatPieChart>
          </div>
            
            <div class="col-3"></div>
        </div>
    </template>
  </div>

    </div>

  </template>
  <script>

  import StatPieChart from '@/components/charts/StatPieChart';
  import StatBarChart from '@/components/charts/StatBarChart';

    export default {
      name: 'DetailedView',
      components:{
        StatPieChart,
        StatBarChart
      },
      props:{
        statistics: {type: Object},
      },
      data() {
        return {
          divshowindex: 0,
        };
      },
      methods: {
        async setdivshowindex(index) {
            this.divshowindex = index;
        },
      }
    }
    </script>
  
    <style>
  
    </style>