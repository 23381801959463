<!-- This is special designed for handling one button clicks for the mini-is test -->
<template>
    <div class="m-lg-1">
        <p v-if="showQuestions" class="mb-5 mt-lg-5 mt-md-3 mb-md-3" >{{surveytitle}}</p>
        <table v-if="showQuestions" class="table table-borderless">
            <template v-for="(row, rowIndex) in chunkedQuestions" :key="`row-${rowIndex}`">
                <tr>
                    <template v-for="(q, colIndex) in row" :key="`col-${rowIndex}-${colIndex}`">
                        <td><Query @query-button-clicked="handleQueryButtonClick" :query="q"></Query></td>
                    </template>
                    <!-- Fill in the remaining columns if the last row is not full -->
                    <td v-if="row.length < questionnaire.numberofCols" :colspan="questionnaire.numberofCols - row.length"></td>
                </tr>
            </template>
        </table>
        <div v-else class="m-5">
            <div class="row">
                <div class="col-2"></div>
                <div class="col-8">
                    <p class="fw-bold">{{$t('youranswer')}} {{ selectedQuestion.text}}</p>
                    <p class="fw-bold">{{$t('miniistesttext1')}} </p>
                    <p class="fw-bold">{{$t('miniistesttext2')}} </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Query from "@/components/survey/Query";
import ResponseService from '@/helpers/apiservices/response/response.service'

export default{
    name: 'MultiColumnQuestions',
    props:{
        questionnaire: {type: Object},
    },
    components: {
        Query
    }, 
    data() {
        return {
            surveytitle: '',
            showQuestions: true,
            selectedQuestion: null
        }
    },
    mounted() {
        this.loadInitialData();
    },
    methods: {
        async loadInitialData() {
            this.selectedQuestion = null; //Ensure clean state
            this.surveytitle = this.questionnaire.survey.title;
        },
        handleQueryButtonClick(question) {

            this.selectedQuestion = question;

            //Finished- store answer
            const responsesurvey = this.createResponseSurvey();
            ResponseService.store(responsesurvey);

            this.showQuestions = false;

            setTimeout(() => {
                this.selectedQuestion = null;
                this.showQuestions = true;
            }, 60000); //  60 seconds
        },
        createResponseSurvey: function(){
            //Map questionnaire object to ReposenseSurvey object for server storage
            const responsesurvey = {
                surveypublicid: this.questionnaire.survey.public_id, 
                timestartepoch: Date.now(), 
                timeendepoch: Date.now(),
                noofclicks: 1,
            }

            const responsequestions = [];

            var questionid = this.selectedQuestion.id;

            //Add answer to each question
            this.questionnaire.questions.forEach(function(question) {

                const responsequestion = {
                    questionid: question.id,
                    booleanvalue: question.id === questionid ? true : false,
                    numericvalue: question.numericvalue === 0 ? question.numericvalue : (question.numericvalue === (undefined | null) ? null : question.numericvalue) ,
                    textvalue: question.textvalue === (undefined) ? null : question.textvalue,
                };

                responsequestions.push(responsequestion);    

            });
            

            responsesurvey.responsequestions = responsequestions;
            return responsesurvey;
        }
    },
    computed: {
        orderedquestions: function(){
                if(this.questionnaire.questions){
                    var sorted = this.questionnaire.questions.sort((a, b) => {return a.orderseq - b.orderseq;});
                    return sorted;
                }
            },
        chunkedQuestions() {
            const chunked = [];
            for (let i = 0; i < this.orderedquestions.length; i += this.questionnaire.numberofCols) {
                chunked.push(this.orderedquestions.slice(i, i + this.questionnaire.numberofCols));
            }
            return chunked;
        },
    }
}
</script>