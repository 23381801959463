<template>
    <div class="container-fluid">
        <QRCodeModal></QRCodeModal>
        <div class="row flex-nowrap">
          <div class="col-3"></div>
          <div class="mt-5 col-8 py-3">
            <div class="ms-3 text-start d-flex align-items-center">
              <i class="fs-1"><font-awesome-icon icon="fa-clipboard-question" /></i> 
              <h5 class="ms-2 card-title">{{ $t("questiontype") }}</h5>  
            </div>
            <div class="mt-3 ms-4 d-flex flex-column align-items-start text-start">
              <span>
                {{$t('qtypeinfo1')}}
              </span>
              <span>
                {{$t('qtypeinfo2')}}
              </span>
              <span>
                {{$t('qtypeinfo3')}}
              </span>
            </div>
            <div class="row">
                <div class="col-6 mt-5 text-start"></div>
                <div class="col-6 mt-5 text-end">
                    <button type="button" v-on:click="storeQuestionType" class="btn btn-isprimary btn-sm">{{$t('newquestiontype')}}</button>
                </div>
            </div>
            
            <div class=" pt-2 mb-5 pe-2 pb-2 bg-success bg-opacity-10">
                <div class="row">
                    <div class="col-1 text-end">
                        <small>{{$t('name')}}</small> 
                    </div>
                    <div class="col-3 text-start">
                        <div class="input-group input-group-sm mb-3">
                            <input type="text" v-model="questiontypeInsert.name" class="form-control" required />
                        </div>
                    </div>
                    <div class="col-2 text-end">
                        <small>{{$t('description')}}</small>
                    </div>
                    <div class="col-6 text-start">
                        <div class="input-group input-group-sm mb-3">
                            <input type="text" v-model="questiontypeInsert.description" class="form-control" required />
                        </div>
                    </div>
                </div>
                <div class="mb-2 row">
                    <div class="col-1">
                    </div>
                    <div class="col-1 text-end">
                        <small>{{$t('starticon')}}</small> 
                    </div>
                    <div class="col-4 text-start">
                        <select v-model="questiontypeInsert.starticon" class="form-select-sm darksel"  >
                            <option value="0">{{$t('selecticon')}}</option>
                            <option v-for="icon in icons" v-bind:value="icon">
                                {{ icon.filename }}
                            </option>
                        </select>
                    </div>
                    <div class="col-1 text-end">
                        <small>{{$t('endicon')}}</small> 
                    </div>
                    <div class="col-1 text-start">
                        <select v-model="questiontypeInsert.endicon" class="form-select-sm darksel"  >
                            <option  value="0">{{$t('selecticon')}}</option>
                            <option v-for="icon in icons" v-bind:value="icon">
                                {{ icon.filename }}
                            </option>
                        </select>
                    </div>
                </div>
                <hr>
                <div class="mb-2 row">
                    <div class="col-2 text-end">
                        <small>{{$t('hassubquestions')}}</small> 
                    </div>
                    <div class="col-1 text-start">
                        <div class="input-group input-group-sm">
                            <input type="checkbox" class="form-check-input" v-model="questiontypeInsert.addsubquestions">
                        </div>
                    </div>
                    <div class="col-1 text-end border">
                        <small>{{$t('min')}}</small>
                    </div>
                    <div class="col-1 text-start">
                        <div class="input-group input-group-sm">
                            <input type="number" maxlength="2" class="form-control" v-model="questiontypeInsert.minint">
                        </div>
                    </div>
                    <div class="col-1 text-end">
                        <small>{{$t('insteps')}}</small>
                    </div>
                    <div class="col-1 text-start">
                        <div class="input-group input-group-sm">
                            <input type="number" maxlength="1" class="form-control" v-model="questiontypeInsert.intstep">
                        </div>
                    </div>
                    <div class="col-2 text-end">
                        <small>{{$t('hexstart')}}</small> 
                    </div>
                    <div class="col-2 text-start">
                        <div class="input-group input-group-sm">
                            <input type="text" class="form-control" v-model="questiontypeInsert.hexstart">
                        </div>
                    </div>
                </div>
                <div class="mb-2 row">
                    <div class="col-2 text-end">
                        <small>{{$t('truefalsevalue')}}</small> 
                    </div>
                    <div class="col-1 text-start">
                        <div class="input-group input-group-sm">
                            <input type="checkbox" class="form-check-input" v-model="questiontypeInsert.booleanvalue">
                        </div>
                    </div>
                    <div class="col-1 text-end border">
                        <small>{{$t('max')}}</small>
                    </div>
                    <div class="col-1 text-start">
                        <div class="input-group input-group-sm">
                            <input type="number" maxlength="2" class="form-control" v-model="questiontypeInsert.maxint">
                        </div>
                    </div>
                    <div class="col-1 text-end">
                        <small>{{$t('intstartvalue')}}</small>
                    </div>
                    <div class="col-1 text-start">
                        <div class="input-group input-group-sm">
                            <input type="number" maxlength="1" class="form-control" v-model="questiontypeInsert.intstartvalue">
                        </div>
                    </div>
                    <div class="col-2 text-end">
                        <small>{{$t('hexend')}}</small> 
                    </div>
                    <div class="col-2 text-start">
                        <div class="input-group input-group-sm">
                            <input type="text" class="form-control" v-model="questiontypeInsert.hexend">
                        </div>
                    </div>
                </div>
                
                <div class="mb-2 row">
                    <div class="col-2 text-end">
                        <small>{{$t('integervalue')}}</small> 
                    </div>
                    <div class="col-1 text-start">
                        <div class="input-group input-group-sm">
                            <input type="checkbox" class="form-check-input" v-model="questiontypeInsert.integerrange">
                        </div>
                    </div>
    
                </div>
                <div class=" mb-2 row">
                    <div class="col-3">
                        <small>{{$t('hexsteps')}}:</small> 
                    </div>
                    <div class="col-9 text-start">
                        <div class="input-group input-group-sm">
                            <input type="text" class="form-control" v-model="questiontypeInsert.hexsteps">
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-5">
                <component is="QuestionType" :typeicons="icons" :questiontype="qt" v-for="qt in questiontypes" :key="qt.id"  @loadInitialData="loadInitialData" >  
                </component>
            </div>
          </div>
        </div>
    </div>
</template>

<script>

import { defineComponent } from 'vue';
import QuestionTypeService from "@/helpers/apiservices/questiontype/questiontype.service"
import Notify from "@/helpers/notify";
import QuestionType from '@/components/QuestionType';
import IconService from '@/helpers/apiservices/icon/icon.service'

export default {
    name: 'QuestionTypes',
    data() {
      return {
        questiontypes:[],
        addNewQuestionType: false,
        questiontypeInsert: {
            id: 0,
            name:'', 
            description:'',
            addsubquestions: false,
            booleanvalue: false,
            integerrange: false,
            minint: 0,
            maxint: 0,
            intstep: 0,
            hexstart: '',
            hexend: '',
            hexsteps: '',
            starticon: null,
            endicon: null

        },
        icons:[]
      };
    },
    components: {
      QuestionType
    },
    mounted() {
        Notify.loadData(null, this.loadInitialData);
    },

    methods: {
        async loadInitialData() {
            this.questiontypes = await QuestionTypeService.questiontypes();
            this.icons = await IconService.icons();
        },
        async storeQuestionType() {
            Notify.loadData("stored", this.store);
        },
        async store(){
            await QuestionTypeService.store(this.questiontypeInsert);
            this.loadInitialData();
            this.addNewQuestionType = false;
        },
    }
    
}
</script>

<style>
.btn-bottom{
    margin:0 !important
}
</style>
    