import axios from "axios";
import ErrorParser from '@/services/error.parser';
import { useAuthStore } from '@/stores/auth.store';

const RoomService = {
  openstatistics: async function (publicid) {
    const requestData = {
      method: "get",
      url: "/api/openstatistics/" + publicid
    };
    const response = await axios(requestData);
    return JSON.parse(JSON.stringify(response.data));
  },

  openstatisticsByDate: async function (publicid,startDate,endDate) {
    const requestData = {
        method: "get",
        url: `/api/openstatistics/${publicid}/${startDate}/${endDate}`
      };
    const response = await axios(requestData);
    return JSON.parse(JSON.stringify(response.data));
  },

  statistics: async function (surveyid) {

    const requestData = {
        method: "get",
        url: "/api/statistics/" + surveyid,
        headers: {
            "authorization": "Bearer " + useAuthStore().user.jwt 
        }
      };
      const response = await axios(requestData);
      return JSON.parse(JSON.stringify(response.data));

  },
  statisticsByDate: async function (surveyid,startDate,endDate) {
    const requestData = {
        method: "get",
        url: `/api/statistics/${surveyid}/${startDate}/${endDate}`,
        headers: {
            "authorization": "Bearer " + useAuthStore().user.jwt 
        }
      };
    const response = await axios(requestData);
    return JSON.parse(JSON.stringify(response.data));
  },

  statisticsByDate: async function (surveyid,startDate,endDate) {
    const requestData = {
        method: "get",
        url: `/api/statistics/${surveyid}/${startDate}/${endDate}`,
        headers: {
            "authorization": "Bearer " + useAuthStore().user.jwt 
        }
      };
    const response = await axios(requestData);
    return JSON.parse(JSON.stringify(response.data));
  },


}

export default RoomService;

