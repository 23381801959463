

<script setup>
  import { RouterLink, RouterView } from 'vue-router';
  
  import { useAuthStore } from '@/stores/auth.store';
  


  const authStore = useAuthStore();

</script>

<template>
  <div class="container-fluid">
      <div>
        <div class="row flex-nowrap">
          <div class="col-3"></div>
          <div class="col-9">
            <Menu v-show="authStore.user"/>
          </div>    
        </div>

        <router-view />
        <notifications position="top center" />
    </div>
  </div>
</template>

<script>

import Menu from "@/components/Menu";

export default {
  name: 'App',
  components: {
    Menu
  },
}
</script>

<style>

@import url('https://fonts.googleapis.com/css?family=Nunito');

#app {
  font-family: 'Nunito', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}



</style>
