<template>
    <tr>
        <td class="align-middle">{{questionnaire.id}}</td>
        <td class="align-middle text-start">{{questionnaire.title}}</td>
        <td class="align-middle text-start">{{questionnaire.description}}</td>
        <td class="text-end">
            <button @click="update" class="m-1 btn btn-islink btn-sm fw-bold">{{$t('edit')}}</button>
        </td>
    </tr>
</template>

<script>

    import router from '@/router';

    export default {
        name: 'Questionnaire',
        props:[
            'questionnaire'
        ],
        data() {
          return {
          };
        },
        methods: {
            async update() {
                router.push({
                    path: "QuestionnaireEdit",
                    query: {id: this.questionnaire.id}
                });
            },
        }
    }
    </script>
    
    <style>
        td{padding:0px !important}
    </style>