<script setup>
    import { Form, Field } from 'vee-validate';
    import * as Yup from 'yup';
    
    import { useAuthStore } from '@/stores/auth.store';
    
    const schema = Yup.object().shape({
        username: Yup.string().required('Email is required'),
        password: Yup.string().required('Password is required')
    });
    
    function onSubmit(values, { setErrors }) {
        const authStore = useAuthStore();
        const { username, password } = values;
    
        return authStore.login(username, password)
            .catch(error => setErrors
            ({ 
                apiError: 'Could not log in. Please check email and password' 
            }));
    }
    </script>
    
    <template>
        <section class="vh-100" style="background-color: #E6DCD7; ">
            <div class="container py-4 h-100">
                <div class="row d-flex justify-content-center align-items-center h-100">
                <div class="col col-sm col-md col-lg-8 col-xl-10">
                    <div class="card" style="border-radius: 1rem;">
                    <div class="row g-0">
                        <div class="col-md-6 col-lg-5 d-none d-md-block" style="background-color: #F1AFA0; opacity: 0.8;">
                        </div>
                        <div class="col-md-6 col-lg-7 d-flex align-items-center">
                        <div class="card-body p-4 p-lg-5 text-black">
            
                            <Form  @submit="onSubmit" :validation-schema="schema" v-slot="{ errors, isSubmitting }">
            
                            <div class="d-flex align-items-center mb-1">
                                <i class="fas fa-cubes fa-2x me-5" style="color: #ff6219;"></i>
                                <span class="h1 fw-bold mb-3"><img class="img-fluid" alt="IDGG MIN SKOLE logo" src="../../assets/Dms_logo_2.png"></span>
                            </div>
            
                            <!--<h5 class="fw-normal mb-3 pb-3" style="letter-spacing: 1px;">{{ $t("login") }}</h5>-->
            
                            <div class="form-outline mb-3">
                                <Field name="username" type="text" class="form-control form-control-md" :class="{ 'is-invalid': errors.username }" />
                                <div class="invalid-feedback">{{errors.username}}</div>
                                <label class="form-label" for="usernamelbl">{{$t('email')}}</label>
                            </div>
            
                            <div class="form-outline mb-2">
                                <Field name="password" type="password" class="form-control form-control-md" :class="{ 'is-invalid': errors.password }" />
                                <div class="invalid-feedback">{{errors.password}}</div>
                                <label class="form-label" for="form2Example27">{{$t('password')}}</label>
                                <div v-if="errors.apiError" class="alert alert-danger mt-3 mb-0">{{errors.apiError}}</div>
                            </div>
            
                            <div class="pt-1 mb-1">
                                <button class="btn btn-dark btn-md btn-block" :disabled="isSubmitting">
                                <span v-show="isSubmitting" class="spinner-border spinner-border-sm mr-1"></span>
                                Login
                                </button>
                            </div>
            
                            <p class="mb-0" style="color: #393f81;"> {{$t('noaccount')}} <a href="/register"
                                style="color: #393f81;">{{$t('registerhere')}}</a></p>
                            </Form>
            
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>

    </template>