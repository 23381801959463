<template>
    <div class="table-container">

        <div class="mt-5 mb-5">
            <strong>{{$t('openstatsTitleToday')}}</strong>
            <div>
                <div class=" row">
                    <div class="col-3"></div>
                    <div class="border-start border-top col-3 text-start custom-bg-color-high statisticscoltitle"><strong>{{$t('openstatscoltitle1')}}</strong></div>
                    <div class="border-start border-top col-3 text-start custom-bg-color-high statisticscoltitle"><strong>{{$t('openstatscoltitle12')}}</strong></div>
                    <div class="border-start"></div>
                </div>
                <div v-for="response in todaysstatistics.questionresponses" :key="response.id" class=" row-fixed-height row">
                    <div class="col-3"></div>
                    <div class="vertically-centered border-start border-top col-3 text-start statisticscoltext">{{ formatText(response.question.text) }}</div>
                    <div class="vertically-centered border-start border-top col-3 text-start">
                        <div class="vertically-centered squares">
                            <span v-for="n in countTrueValues(response.booleancount)" :key="n" class="square"></span>
                        </div>
                        <span class="squares-count" v-if="countTrueValues(response.booleancount) > 0">{{ countTrueValues(response.booleancount) }}</span>
                    </div>
                    <div class="col-3 border-start"></div>
                </div>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="border-start border-top col-3 "></div>
                    <div class="border-start border-top col-3"></div>
                </div>
            </div>
        </div>

        <div>
            <strong>{{$t('openstatsTitleAll')}}</strong>
            <div>
                <div class=" row">
                    <div class="col-3"></div>
                    <div class="border-start border-top col-3 text-start custom-bg-color statisticscoltitle"><strong>{{$t('openstatscoltitle1')}}</strong></div>
                    <div class="border-start border-top col-3 text-start custom-bg-color statisticscoltitle"><strong>{{$t('openstatscoltitle12')}}</strong></div>
                    <div class="border-start"></div>
                </div>
                <div v-for="response in statistics.questionresponses" :key="response.id" class=" row-fixed-height row">
                    <div class="col-3"></div>
                    <div class="vertically-centered border-start border-top col-3 text-start statisticscoltext">{{ formatText(response.question.text) }}</div>
                    <div class="vertically-centered border-start border-top col-3 text-start">
                        <div class="vertically-centered squares">
                            <span v-for="n in countTrueValues(response.booleancount)" :key="n" class="square-small"></span>
                        </div>
                        <span class="squares-count" v-if="countTrueValues(response.booleancount) > 0">{{ countTrueValues(response.booleancount) }}</span>
                    </div>
                    <div class="col-3 border-start"></div>
                </div>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="border-start border-top col-3 "></div>
                    <div class="border-start border-top col-3"></div>
                </div>
            </div>
        </div>
    </div>
    
</template>


<script>

    import { useRoute } from 'vue-router'
    import StatisticsService from "@/helpers/apiservices/statistics/statistics.service"; 

    export default {
        name: 'OpenStatistics',
        components: {
        },
        data() {
            return {
                statistics: {},
                todaysstatistics: {},
            }
        },
        mounted() {
            this.loadInitialData();
        },
        methods: {
            async loadInitialData() {
                const route = useRoute();
                if(route.query.id){
                    // Get today's date
                    const today = new Date();
                    
                    // Start time at 4 AM
                    today.setHours(4,0,0,0);
                    const todayFrom = Math.floor(today.getTime() / 1000); // Convert to seconds

                    // End time at midnight
                    today.setHours(23,59,59,999);
                    const todayTo = Math.floor(today.getTime() / 1000); // Convert to seconds
                    this.todaysstatistics = await StatisticsService.openstatisticsByDate(route.query.id, todayFrom, todayTo);

                    //All statistics
                    this.statistics = await StatisticsService.openstatistics(route.query.id);
                }
            },
            countTrueValues(booleanCount) {
                const trueItem = booleanCount.find(item => item.value === true);
                return trueItem ? trueItem.count : 0;
            },
            formatText(text) {
                return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
            }
          },
        }
    </script>
  
  <style>
    .square {
        display: inline-block;
        width: 7px;
        height: 15px;
        background-color: black;
        margin-right: 1px;
    }
    .square-small {
        display: inline-block;
        width: 2px;
        height: 15px;
        background-color: black;
        margin-right: 1px;
    }
    .statisticscoltitle {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
    }
    .statisticscoltext {
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
    }
    .row-fixed-height {
        height: 25px; /* Corrected height with unit */
    }
    .custom-bg-color {
        background-color: #DADADA; 
    }
    .custom-bg-color-high {
        background-color: #87AFC7; 
    }
    .vertically-centered {
        display: flex;
        align-items: center; /* This centers the content vertically */
        height: 100%; /* Ensure the column has a height for align-items to work */
    }

    .squares-count {
        margin-left: 10px; /* Adjust as needed */
        font-family: 'Roboto', sans-serif;
        font-size: 11px;
        /* Additional styles here */
    }
</style>