<template>

    <div class="mt-5 ms-5 me-5 mb-4 smalltext">
        <hr class="bg-danger border-2 border-top border-danger">
        <div class="row mb-2">
            <div class="col-12 border text-start">
                <component is="QuestionTimePickers" :question="question" :key="question.id"></component>
            </div>
        </div>
        <div class="row">
            <div class="col-1 border text-start">
                <span><small>{{question.id}}</small></span>
                <small class="ps-3">Sek.:</small><input class="border-0 mt-1" type="text" v-model="question.orderseq" maxlength="2" size="2" required></div>
            <div class="col-3 border">
                <div>
                    <input type="text" class="smalltext form-control border-0 p-0 mt-1" aria-describedby="inputGroup-sizing-default" :placeholder="$t('text')" v-model="question.text" required>
                </div>
                
            </div>
            <div class="col-3 border text-start">
                <span><small>{{$t('selectquestiontype')}}</small></span>
                <select v-model="question.type" class="ms-2 form-select-mini" required>
                    <option disabled value="0">{{$t('selectquestiontype')}}</option>
                    <option v-for="type in questiontypes" v-bind:value="type">
                        {{ type.name }}
                    </option>
                </select>
                <br/>
                <span><small>{{$t('backgroundhex')}}</small></span>
                <input type="text" maxlength="10" style="width: 100px;" class="ms-3 smalltext border-0 p-0" v-model="question.backgroundcolorhex">
            </div>
            <div class="col-4 border text-start">
                <span><small>{{$t('selecticon')}}</small></span>
                <select v-model="question.icon" class="ms-2 form-select-mini" required>
                    <option disabled value="0">{{$t('selecticon')}}</option>
                    <option v-for="icon in icons" v-bind:value="icon">
                        {{ icon.filename }}
                    </option>
                </select>
            </div>
            <div class="col-1 border">
                <button type="button" v-on:click="deleteQuestion" class="ms-2 btn btn-islink btn-sm p-1">
                    <font-awesome-icon icon="fa-trash" />
                </button>
                <button v-if="question.type" type="button" v-on:click="addSubQuestion" class="m-1 btn btn-islink btn-sm greentext" :title="$t('newsubquestion')">
                    <font-awesome-icon icon="fa-solid fa-plus" class="green-icon" />
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-12 border text-start">
                <span class="me-1">{{$t('showinfo')}}</span>
                <input class="form-check-input mt-0" type="checkbox" v-model="question.showinfo">
                <textarea maxlength="500" class="smalltext form-control" id="exampleFormControlTextarea1" rows="2" v-model="question.infotext" required></textarea>
            </div>
        </div>

        {{$t('subqtitle')}}
        <component is="SubQuestion" :subquestion="sq" :questionnaireid="questionnaireid" :questiontypes="questiontypes" :index="index" :icons="icons" v-for="(sq, index) in sortedSubquestions" :key="sq.id" @removeItem="removeSubQuestion">  
        </component>

    </div>

</template>

<script>

import SubQuestion from '@/components/SubQuestion'
import QuestionTimePickers from '@/components/QuestionTimePickers'
import QuestionService from '@/helpers/apiservices/question/question.service'

export default {
    name: 'Question',
    components: {
        SubQuestion,
        QuestionTimePickers
    },
    props:[
        'question',
        'questiontypes',
        'icons',
        'index',
        'questionnaireid'
    ],
    data() {
      return {
        selectedType: "",
      };
    },

    methods: {
        deleteQuestion() {
            this.delete();
        },

        async delete(){
            if( this.question.id != 0){
                await QuestionService.delete(this.question.id); 
            }
            this.$emit('removeItem', this.index);
        },
        removeQuestion(index){
            this.question.subquestions.splice(index, 1);
        },
        addSubQuestion(){
            var t = this.question.subquestions;

            if(t === undefined){
                this.question.subquestions = [{id: 0, text: ''}];
            }
            else
                this.question.subquestions.push({id: 0, text: ''});
        },
        removeSubQuestion(index){
            this.question.subquestions.splice(index, 1);
        }
    },
    computed: {
        sortedSubquestions() {
        if (this.question.subquestions && this.question.subquestions.length > 0) {
            return this.question.subquestions.sort((a, b) => a.orderseq - b.orderseq);
        }
        return [];
        }
    }
    
}
</script>

<style>
.wrapper{
    width:100px;
    height: 50px;
}

.no-border {
    border: 0;
    box-shadow: none; /* You may want to include this as bootstrap applies these styles too */
}
.smalltext {
    font-size: 12px;
    font-weight: bold
}
a.smalltext {
    font-size: 12px;
    font-weight: bold
}
input.smalltext {
    font-size: 12px;
    font-weight: bold;
}
textarea.smalltext {
    font-size: 12px;
    font-weight: bold;
}
select.form-select-mini {
    height: auto;
    line-height: 25px;
    font-size: 12px;
    font-weight: bold;
}
.green-icon {
    color: #28a745; 
}

</style>
    