<template>
    <div class="mb-4 col-6">
          <label class="mb-3 "></label>
          <div style="max-height: 444px;" class="text-start">
            <apexchart v-if="areaseries.length > 0"
                width="800" height="300"
                type="area"
                :options="areachartOptions"
                :series="areaseries.length > 0 ? areaseries : []"
                :key="areaseries.length"
                ></apexchart>
        </div>
    </div>
</template>

<script>
import ApexchartHelper from "@/helpers/settings/apexcharts";
import moment from 'moment';
import VueApexCharts from "vue3-apexcharts";

export default {
name: 'AreaChart',
props:{
    responses: {type: Array}
},
data() {
  return {
    areachartOptions: ApexchartHelper.areasettings(1111, true),
    areaseries: [],
  };
},
components: {
    apexchart: VueApexCharts,
    ApexchartHelper
},
mounted() {
    this.setAreachartSeries();
},
watch: { 
    responses: function(newVal, oldVal) { // watch it
        this.setAreachartSeries();
    },
},
methods: {
    async setAreachartSeries(){

        // Determine the earliest timestart and latest timeend values from the this.responses array
        const earliestTimestamp = moment.min(this.responses.map((response) => moment.utc(response.timestart)));
        const latestTimestamp = moment.max(this.responses.map((response) => moment.utc(response.timeend)));

        // Set the start and end dates for grouping the survey responses by hour
        const startDate = earliestTimestamp.clone().startOf('hour');
        const endDate = latestTimestamp.clone().endOf('hour');

        // Create an array of all the hours in the date range
        const hoursInRange = [];
        for (let hour = startDate.clone(); hour.isBefore(endDate); hour.add(1, 'hour')) {
            hoursInRange.push(hour.clone());
        }

        // Group the survey responses by hour for the date range
        const surveyCountsByHour = hoursInRange.map((hour) => {
            const hourStart = hour.clone();
            const hourEnd = hourStart.clone().add(1, 'hour');

            const responsesInHour = this.responses.filter((response) => {
                const responseTime = moment.utc(response.timestart);
                return responseTime.isBetween(hourStart, hourEnd);
            });
            return [hour.toDate(), responsesInHour.length];
        });

        this.areaseries = [{
            data: surveyCountsByHour
        }];

    },
}

}
</script>

<style>

</style>
