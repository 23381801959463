
import {Norwegian} from 'flatpickr/dist/l10n/no.js';

const flatpickr = {
    config() {
      return {
        mode: "range",
        locale: Norwegian
      };
    },
  
  };
  
  export default flatpickr;