<template>

  <div class="container-fluid">
    <div class="row flex-nowrap">
      <div class="col-3"></div>
      <div class="mt-5 col-8 py-3">
        <div class="ms-3 text-start d-flex align-items-center">
          <i class="fs-1"><font-awesome-icon icon="fa-file-invoice" /></i> 
          <h5 class="ms-2 card-title">{{$t('questionnaire')}}</h5>  
        </div>
        <div class="mt-3 ms-4 d-flex flex-column align-items-start">
          <span>
           {{$t('questionnaireinfo1')}}
          </span>
          <span>
           {{$t('questionnaireinfo2')}}
          </span>
          <span>
           {{$t('questionnaireinfo3')}}
          </span>
          <span>
            <button type="button" @click="addQuestionnaire" class="m-1 btn btn-islink btn-sm fw-bold">{{$t('newquestionnaire')}}</button>
        </span>
        </div>
        <div>
          <table class="mt-5 table table-striped">
            <thead>
              <tr>
                <th scope="col" class="text-start">
                  <span>
                    <font-awesome-icon icon="fa-paperclip"></font-awesome-icon> 
                    Id
                  </span>
                </th>
                <th scope="col" class="text-start">
                  <span>
                    <font-awesome-icon icon="fa-file-invoice" /> {{$t('name')}}
                  </span>
                </th>
                <th scope="col" class="text-start">
                  <span>
                    <font-awesome-icon icon="fa-newspaper" /> {{$t('description')}}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <component is="Questionnaire" :questionnaire="q" v-for="q in questionnaires" :key="q.id">  
              </component>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { defineComponent } from 'vue';
import router from '@/router';
import Notify from "@/helpers/notify";
import QuestionnaireService from "@/helpers/apiservices/questionnaire/questionnaire.service"
import Questionnaire from '@/components/Questionnaire'


export default {
    name: 'Questionnaires',
    data() {
      return {
        questionnaires:[],
      };
    },
    components: {
      Questionnaire
    },
    mounted() {
        Notify.loadData(null, this.loadInitialData);
    },
    methods: {
      async loadInitialData() {
            this.questionnaires = await QuestionnaireService.questionnaires();
      },
      addQuestionnaire() {
        router.push({
          path: "QuestionnaireEdit"
        });
      }
    }
    
}
</script>

<style>

</style>
    