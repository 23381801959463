
<template>
    <section class="vh-100" style="background-color: #E6DCD7; ">
        <div class="container py-4 h-100">
            <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col col-sm col-md col-lg-8 col-xl-10">
                <div class="card" style="border-radius: 1rem;">
                <div class="row g-0">
                    <div class="col-md-6 col-lg-5 d-none d-md-block" style="background-color: #F1AFA0; opacity: 0.8;">
                    </div>
                    <div class="col-md-6 col-lg-7 d-flex align-items-center">
                    <div class="card-body p-4 p-lg-5 text-black">
                        <form @submit.prevent="registeruser">
                            <div class="d-flex align-items-center mb-1">
                                <i class="fas fa-cubes fa-2x me-5" style="color: #ff6219;"></i>
                                <span class="h1 fw-bold mb-3"><img class="img-fluid" alt="IDGG MIN SKOLE logo" src="../../assets/Dms_logo_2.png"></span>
                                </div>
                            <!--<h5 class="fw-normal mb-3 pb-3" style="letter-spacing: 1px;">{{ $t("regemailpassw") }}</h5>-->
                            <div class="form-outline mb-3">
                                <input id="usernamelbl" name="username" type="text" class="form-control form-control-md" v-model="email" required/>
                                <label class="form-label" for="usernamelbl">{{$t('email')}}</label>
                            </div>
                            <div class="form-outline mb-2">
                                <input id="passwordlbl" name="password" type="password" class="form-control form-control-md" v-model="password" required/>
                                <label class="form-label" for="passwordlbl">{{$t('password')}}</label>
                                <div v-if="apiError" class="alert alert-danger mt-3 mb-0">{{apiError}}</div>
                            </div>
                            <div class="form-outline mb-2">
                                <input id="confirmpasswordlbl" name="confirmpassword" type="password" class="form-control form-control-md" v-model="confirmedpassword" required/>
                                <label class="form-label" for="passwordlbl">{{$t('confirmpassword')}}</label>
                            </div>
                            <div class="pt-1 mb-1">
                                <button type="submit" class="btn btn-dark btn-md btn-block">{{$t('register')}}</button>
                            </div>
                            <p class="mb-0" style="color: #393f81;"> {{$t('hasaccount')}} <a href="/login"
                                style="color: #393f81;">{{$t('loginhere')}}</a></p>
                        </form>

                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
</template>

<script>

import { useAuthStore } from '@/stores/auth.store';

export default {
    name: 'Register',
    data() {
        return {
            email: "",
            password: "",
            confirmedpassword: "",
            apiError: ""
        };
    },
    mounted() {
        //Set upp validation of confirmed password
        var password = document.getElementById("passwordlbl")
            , confirm_password = document.getElementById("confirmpasswordlbl");
        password.onchange = this.validatePassword;
        confirm_password.onkeyup = this.validatePassword;
    },
    methods: {
        async registeruser(){
            const authStore = useAuthStore();
            authStore.register(this.email, this.password)
            .catch((error) =>{
                this.apiError = "Could not register user";
            });
        },
        validatePassword(){
            var password = document.getElementById("passwordlbl")
            , confirm_password = document.getElementById("confirmpasswordlbl");

            if(password.value != confirm_password.value) {
                confirm_password.setCustomValidity("Passwords Don't Match");
            } else {
                confirm_password.setCustomValidity('');
            }
        }
    }
    
}
</script>
        
<style>

</style>