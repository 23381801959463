<template>

  <div class="container-fluid">

    <RegisterBuildingModal @reloadBuildings="loadBuildings"></RegisterBuildingModal>
    <RegisterRoomModal @reloadRooms="loadRooms"></RegisterRoomModal>

    <div class="row flex-nowrap ">
      <div class="col-3"></div>
      <div class="mt-5 col-8 py-3 ">
        <div class="ms-3 text-start d-flex align-items-center">
          <i class="fs-1"><font-awesome-icon icon="fa-square-poll-vertical" /></i> 
          <h5 class="ms-2 card-title">{{ $t("newsurvey") }}</h5>  
        </div>
        <div class="mt-3 ms-4 d-flex flex-column align-items-start text-start">
          <span>
            {{$t('newsurveyinfo1')}}
          </span>
          <span>
           {{$t('newsurveyinfo2')}}
          </span>
          <span>
           {{$t('newsurveyinfo3')}}
          </span>
        </div>
        <div class="mt-5 p-3 bg-secondary bg-opacity-10">
          <div class="row">
            <div class="col-4 text-start">
              <div class="input-group input-group-sm mb-3">
                <span class="input-group-text" id="inputGroup-sizing-sm"><font-awesome-icon icon="fa-key" class="me-1" />{{$t('code')}}:</span>
                <span class="input-group-text" id="inputGroup-sizing-sm">{{ survey.public_id }}</span>
              </div>
            </div>
          </div>
          <form  @submit.prevent="store">
            <div class="mt-3 row">
              <div class="col-4 text-start">
                <label  for="surveyName">{{$t('name')}}</label>
              </div>
              <div class="col-2 text-start">
                <label for="buildingSelect">{{$t('choosequestionnaire')}}</label>
              </div>
              <div class="col-3 text-start">
                <label for="buildingSelect">{{$t('chooseschool')}}</label>
              </div>
              <div class="col-3 text-start">
                <label for="roomSelect">{{$t('chooseclassroom')}}</label>
              </div>
            </div>
            <div class="mt-0 row">
              <div class="col-4">
                <div class="input-group input-group-sm mb-3">
                  <input type="text" id="surveyname" v-model="survey.title" class="form-control" required />
                </div>
              </div>
              <div class="col-2">
                <select v-model="survey.questionnaire" class="form-select form-select-sm mb-3" required>
                  <option disabled value="0">{{$t('choosequestionnaire')}}</option>
                  <option v-for="q in questionnaires" v-bind:value="q">
                    {{ q.title }}
                  </option>
                </select>
              </div>
              <div class="col-3">
                <select v-model="survey.building" class="form-select form-select-sm mb-3" @change="onChangeBuilding()" required>
                  <option disabled value="0">{{$t('chooseschool')}}</option>
                  <option v-for="building in buildings" v-bind:value="building">
                      {{ building.text }}
                  </option>
                </select>
                <button type="button" data-bs-toggle="modal" data-bs-target="#buildingModal" class="form-control m-0 p-0 btn btn-islink btn-sm text-end">{{$t('newschool')}}</button>

              </div>
              <div class="col-3">
                <select v-model="survey.room" class="form-select form-select-sm mb-3" required>
                  <option disabled value="0">{{$t('chooseclassroom')}}</option>
                  <option v-for="room in rooms" v-bind:value="room">
                      {{ room.text }}
                  </option>
                </select>
                <button type="button" data-bs-toggle="modal" data-bs-target="#roomModal" class="form-control m-0 p-0 btn btn-islink btn-sm text-end">{{$t('newclassroom')}}</button>
                   
              </div>
            </div>
            <div class="mt-3 row">
              <div class="col-4 text-start">
                <label  for="surveyName">{{$t('peopleinRoom')}}</label>
              </div>
            </div>
            <div class="mt-3 row">
              <div class="col-4 text-start">
                <input class="form-check-input me-2" type="checkbox" value="" id="hasreceivedprojectinfo" v-model="survey.gotprojectinfo"/>
                <label class="form-check-label" for="hasreceivedprojectinfo">{{$t('hasreceivedprojectinfo')}}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-4 text-start">
                <input class="form-check-input me-2" type="checkbox" value="" id="hasreceivedtopicinfo" v-model="survey.gottopicinfo"/>
                <label class="form-check-label" for="hasreceivedtopicinfo">{{$t('hasreceivedtopicinfo')}}</label>
              </div>
            </div>
            <button v-show="survey.id===0" type="submit" class="mt-4 btn btn-isprimary btn-sm btn-block">{{$t('createsurvey')}}</button>
            <button v-show="survey.id!==0" type="submit" class="mt-4 me-2 btn btn-isprimary btn-sm btn-block">{{$t('updatesurvey')}}</button>
            <button v-show="survey.id!==0" type="button" class="mt-4 btn btn-isprimary btn-sm btn-block">{{$t('deletesurvey')}}</button>
          </form>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import Notify from "@/helpers/notify";
import RegisterBuildingModal from "@/components/RegisterBuildingModal"
import RegisterRoomModal from "@/components/RegisterRoomModal"
import BuildingService from "@/helpers/apiservices/building/building.service"
import RoomService from "@/helpers/apiservices/room/room.service"
import QuestionnaireService from "@/helpers/apiservices/questionnaire/questionnaire.service"
import SurveyService from "@/helpers/apiservices/survey/survey.service"
import { useAuthStore } from '@/stores/auth.store';
import { useRoute } from 'vue-router'

export default {
    name: 'Survey',
    data() {
      return {
        survey: {
          id:0,
          gotprojectinfo: false,
          gottopicinfo: false,
          userid: useAuthStore().user.id,
          room: {},
          building: {},
          questionnaire: {}
        },
        selectedbuilding: {},
        selectedroom:{},
        selectedquestionnaire:{},
        buildings:[],
        rooms:[],
        questionnaires:[],

      };
    },
    components: {
        RegisterBuildingModal,
        RegisterRoomModal
    },
    mounted() {
        Notify.loadData(null, this.loadInitialData);
    },

    methods: {
        async loadInitialData() {

          const route = useRoute();
          if(route.query.id){
            this.survey = await SurveyService.survey(route.query.id);
          }
          await this.loadBuildings();
          await this.loadRooms();
          this.questionnaires = await QuestionnaireService.questionnaires();

        },
        async loadBuildings(){
          this.buildings = await BuildingService.buildings();
        },
        async onChangeBuilding(){
          this.rooms = [];
          if(this.survey.building != undefined){
             this.rooms = await RoomService.rooms(this.survey.building.id); 
          }
        },
        async loadRooms(){
            this.rooms = [];
            if(this.survey.building.id != undefined){
              this.rooms = await RoomService.rooms(this.survey.building.id); 
            }
        },
        async store(){
          if(this.survey.id === 0)
            Notify.loadData(null, this.storeData);
          else
            Notify.loadData(null, this.updateData);
        },
        async storeData(){
          this.survey = await SurveyService.store(this.survey);
        },
        async updateData(){
          await SurveyService.update(this.survey);
        }  
    }
    
}
</script>

<style>
.formbg{
    background-image: linear-gradient(to right, rgba(172, 134, 121, .2), rgba(240, 174, 158, .2));
}
.headerbg{
    background-color: rgba(173, 212, 97, .2) !important;
}
.csm{
    font-size: x-small;
}
</style>
    