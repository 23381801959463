
<script setup>
  
</script>

<template>

    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
          <a class="navbar-brand" href="/admin">{{ $t("hjem") }}</a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">

              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="/dashboard">{{ $t("dashboard") }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="/survey">{{ $t("newsurvey") }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="/mysurveys">{{ $t("mysurveys") }}</a>
              </li>
              <li v-if="authUser !==null && authUser.admin !==null && authUser.admin" class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {{ $t("administration") }}
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><a class="dropdown-item" href="/questionnaires">{{ $t("questionnaire") }}</a></li>
                  <li><hr class="dropdown-divider"></li>
                  <li><a class="dropdown-item" href="/questiontypes">{{ $t("questiontype") }}</a></li>
                  <li><a class="dropdown-item" href="/icons">{{ $t("icons") }}</a></li>
                  <li><a class="dropdown-item" href="/actions">{{ $t("actions") }}</a></li>
                  <li><a class="dropdown-item" href="/actiontemplates">{{ $t("actiontemplates") }}</a></li>
                </ul>
              </li>
            </ul>
            <form class="d-flex me-5">
                <div class="dropdown">
                    <button class="btn btn-light dropdown-toggle smalldrop" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <font-awesome-icon icon="fa-user" /><span class="ms-1 d-none d-sm-inline">{{authUser?.email}}</span>
                    </button>
                          
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li><a class="dropdown-item smalldrop" href="#" @click.prevent="signout"><small>Sign out</small></a></li>
                    </ul>
                  </div>
            </form>
          </div>
        </div>
      </nav>
</template>

<script>

export default {
  inject: ['$useAuthStore'],
  name: "Menu",
  data() {
      return {
          authUser: {},
      };
  },
  mounted(){
      const auth = this.$useAuthStore();
      this.authUser = auth.user;
  },
  methods: {
      async signout(){
          this.$useAuthStore().logout()
      }
}
};
</script>

<style>
  .smalldrop{
      font-size: 12px !important
  }
</style>
  