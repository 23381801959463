var tipsubqactions = require('./diggminskole.json'); //with path

let tips = {


    tipOfTheDay(responsesurvey){

        var poswithexception = 0;
        var totanswers = 0;
        var relevanttipsubactions = [];

        responsesurvey.responsequestions.forEach(qr => {
            if(qr.questionid !== 49){ //Except Min helse
                if(qr.numericvalue !== undefined){
                    totanswers++;
                    if(qr.numericvalue === 1 || qr.numericvalue === 2){
                        poswithexception++;
                    }
                    else if(qr.numericvalue === 3){
                        //Add all actions on this sub question
                        qr.responsesubquestions.forEach(sqr => {
                            var subaction = tipsubqactions.find(sqa => sqa.subquestion.id === sqr.subquestionid );
                            relevanttipsubactions.push(subaction);
                        });
                    }
                    else if(qr.numericvalue === 4){
                        //If subquestion selected
                        qr.responsesubquestions.forEach(sqr => {
                            //Special case: temperature, this one will have two actions : one for cold and one for hot temp based on the two coldest/hotest values
                            if(sqr.subquestionid===54){
                                var subaction;
                                //cold
                                if(sqr.numericvalue === '1' || sqr.numericvalue === '2'){
                                    subaction = tipsubqactions.find(sqa => sqa.subquestion.id === sqr.subquestionid &&  sqa.subquestion.title === 'KALDT' );
                                }
                                //hot
                                else if(sqr.numericvalue === '6' || sqr.numericvalue === '7'){
                                    subaction = tipsubqactions.find(sqa => sqa.subquestion.id === sqr.subquestionid &&  sqa.subquestion.title === 'VARMT' );
                                }
                                relevanttipsubactions.push(subaction);
                            }
                            else if(sqr.booleanvalue === true){

                                var subaction = tipsubqactions.find(sqa => sqa.subquestion.id === sqr.subquestionid );
                                relevanttipsubactions.push(subaction);

                            }
                        });
                    }

                }
            }
        });

        var totpercentage = (poswithexception/totanswers) * 100;
        if(totpercentage >=75){
            return "Takk for at du deltok i vår spørreundersøkelse. Din mening er viktig for oss!";
        }
        else{
            //Get all orange
            //The spesific reds
            //randomly select one of them as a tip for the day
            if(relevanttipsubactions.length > 0){
                var randomaction = relevanttipsubactions[relevanttipsubactions.length * Math.random() | 0];
                return randomaction.subquestion.text;
            }
            return '';
        }


        
    }
} 
export default tips