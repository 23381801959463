import axios from "axios";


const SpeechApiService = {

  singlemessage: async function (description, text) {
    const requestData = {
      method: "GET",
      responseType: 'blob', // Important to handle binary data
      url: "/api/generate-speech/" + description + text
    };

    const response = await axios(requestData);
    return response;
  }
}

export default SpeechApiService;

