<template>
    <div class="mb-0 input-group smalltext">

        <span id="inputGroup-sizing-default">{{$t('timeforq')}}</span>
        <div>
            <span class="ms-3 me-1">{{$t('always')}}</span>
            <input class="form-check-input mt-0" type="checkbox" v-model="question.showalways"  v-on:click="showalways_click">
        </div>
                
        <div>
            <span class="ms-3 me-1">{{$t('spesificdays')}}</span>
            <input class="form-check-input mt-0" type="checkbox" v-model="question.showondays" v-on:click="showondays_click">
        </div>
        <div class="ms-3"  v-show="question.showondays">
            <span class="me-1">{{$t('monday')}}</span>
            <input class="form-check-input mt-0" type="checkbox" v-model="question.showonmonday">
        </div>
        <div class="ms-3" v-show="question.showondays">
            <span class="me-1">{{$t('tuesday')}}</span>
            <input class="form-check-input mt-0" type="checkbox" v-model="question.showontuesday">
        </div>
        <div class="ms-3" v-show="question.showondays">
            <span class="me-1">{{$t('wednesday')}}</span>
            <input class="form-check-input mt-0" type="checkbox" v-model="question.showonwednesday">
        </div>
        <div class="ms-3" v-show="question.showondays">
            <span class="me-1">{{$t('thursday')}}</span>
            <input class="form-check-input mt-0" type="checkbox" v-model="question.showonthursday">
        </div>
        <div class="ms-3" v-show="question.showondays">
            <span class="me-1">{{$t('friday')}}</span>
            <input class="form-check-input mt-0" type="checkbox" v-model="question.showonfriday">
        </div>
        <div class="ms-3" v-show="question.showondays">
            <span class="me-1">{{$t('saturday')}}</span>
            <input class="form-check-input mt-0" type="checkbox" v-model="question.showonsaturday">
        </div>
        <div class="ms-3" v-show="question.showondays">
            <span class="me-1">{{$t('sunday')}}</span>
            <input class="form-check-input mt-0" type="checkbox" v-model="question.showonsunday">
        </div>
        <div class="ms-3">
            <span class="me-1">{{$t('spesifictime')}}</span>
            <input class="form-check-input mt-0" type="checkbox" v-model="question.showontime">
        </div>
        <div class="ms-3">
            <select v-model="question.hourstart" class="smalltext">
                <option selected value=""></option>
                <option v-for="hour in selectedhours" :value="hour">
                    {{ hour }}
                </option>
            </select>
            <select v-model="question.hourend" class="smalltext">
                <option selected value=""></option>
                <option v-for="hour in selectedhours" :value="hour">
                    {{ hour }}
                </option>
            </select>
        </div>
    </div>
</template>
<script>
export default {
    name: 'QuestionTimePickers',
    props:[
        'question'
    ],
    data() {
      return {
      };
    },
    methods: {
        showondays_click(){
            this.question.showalways = false;
        },
        showalways_click(){
            this.question.showondays = false;
        },
    },
    computed: {
        selectedhours: function(){
            const arr = [];
            for (let i = 0; i < 25; i++) {
                var t = i < 10 ? `0${i}` : `${i}`;
                arr.push(`${t}:00`);
                if(i !== 24) arr.push(`${t}:30`);
            }
            return arr;
        },        
    },
    
}
</script>
<style>
.wrapper{
    width:100px;
    height: 50px;
}

</style>