  <template>

    <div>
        <div class="row">
            <div class="col-6 text-start">
                <font-awesome-icon icon="fa-key" class="fa-2xs me-1" /><small>{{questiontype.id}}</small>
            </div>
            <div class="text-end col-5">
                <button @click="deleteQuestionType" class="align-middle btn btn-islink btn-sm pb-0 mb-0 link-isdanger">{{$t('delete')}}</button>
            </div>
            
            <div class="text-end col-1">
                <button type="button" v-on:click="updateQuestionType" class=" btn btn-islink btn-sm pb-0 mb-0 fw-bold">{{$t('save')}}</button>
            </div>
        </div>
        <div class="pt-2 mb-5 pe-2 pb-2 bg-secondary bg-opacity-10">
            <div class="row">
                <div class="col-1 text-end">
                    <small>{{$t('name')}}</small> 
                </div>
                <div class="col-3 text-start">
                    <div class="input-group input-group-sm mb-3">
                        <input type="text" v-model="questiontype.name" class="form-control" required />
                    </div>
                </div>
                <div class="col-2 text-end">
                    <small>{{$t('description')}}</small>
                </div>
                <div class="col-6 text-start">
                    <div class="input-group input-group-sm mb-3">
                        <input type="text" v-model="questiontype.description" class="form-control" required />
                    </div>
                </div>
            </div>
            <div class="mb-2 row">
                <div class="col-1">
                </div>
                <div class="col-1 text-end">
                    <small>{{$t('starticon')}}</small> 
                </div>
                <div class="col-4 text-start">
                    <select v-model="questiontype.starticon" class="form-select-sm darksel"  >
                        <option value="0">{{$t('selecticon')}}</option>
                        <option v-for="icon in typeicons" v-bind:value="icon">
                            {{ icon.filename }}
                        </option>
                    </select>
                </div>
                <div class="col-1 text-start">
                    <small>{{$t('endicon')}}</small> 
                </div>
                <div class="col-1 text-end">
                    <select v-model="questiontype.endicon" class="form-select-sm darksel"  >
                        <option value="0">{{$t('selecticon')}}</option>
                        <option v-for="icon in typeicons" v-bind:value="icon">
                            {{ icon.filename }}
                        </option>
                    </select>
                </div>
            </div>
            <hr>
            <div class="mb-2 row">
                <div class="col-2 text-end">
                    <small>{{$t('hassubquestions')}}</small> 
                </div>
                <div class="col-1 text-start">
                    <div class="input-group input-group-sm">
                        <input type="checkbox" class="form-check-input" v-model="questiontype.addsubquestions">
                    </div>
                </div>
                <div class="col-1 text-end border">
                    <small>{{$t('min')}}</small>
                </div>
                <div class="col-1 text-start">
                    <div class="input-group input-group-sm">
                        <input type="number" maxlength="2" class="form-control" v-model="questiontype.minint">
                    </div>
                </div>
                <div class="col-1 text-end">
                    <small>{{$t('insteps')}}</small>
                </div>
                <div class="col-1 text-start">
                    <div class="input-group input-group-sm">
                        <input type="number" maxlength="1" class="form-control" v-model="questiontype.intstep">
                    </div>
                </div>
                <div class="col-2 text-end">
                    <small>{{$t('hexstart')}}</small> 
                </div>
                <div class="col-2 text-start">
                    <div class="input-group input-group-sm">
                        <input type="text" class="form-control" v-model="questiontype.hexstart">
                    </div>
                </div>
            </div>
            <div class="mb-2 row">
                <div class="col-2 text-end">
                    <small>{{$t('truefalsevalue')}}</small> 
                </div>
                <div class="col-1 text-start">
                    <div class="input-group input-group-sm">
                        <input type="checkbox" class="form-check-input" v-model="questiontype.booleanvalue">
                    </div>
                </div>
                <div class="col-1 text-end border">
                    <small>{{$t('max')}}</small>
                </div>
                <div class="col-1 text-start">
                    <div class="input-group input-group-sm">
                        <input type="number" maxlength="2" class="form-control" v-model="questiontype.maxint">
                    </div>
                </div>
                <div class="col-1 text-end">
                    <small>{{$t('intstartvalue')}}</small>
                </div>
                <div class="col-1 text-start">
                    <div class="input-group input-group-sm">
                        <input type="number" maxlength="1" class="form-control" v-model="questiontype.intstartvalue">
                    </div>
                </div>
                <div class="col-2 text-end">
                    <small>{{$t('hexend')}}</small> 
                </div>
                <div class="col-2 text-start">
                    <div class="input-group input-group-sm">
                        <input type="text" class="form-control" v-model="questiontype.hexend">
                    </div>
                </div>
            </div>
            
            <div class="mb-2 row">
                <div class="col-2 text-end">
                    <small>{{$t('integervalue')}}</small> 
                </div>
                <div class="col-1 text-start">
                    <div class="input-group input-group-sm">
                        <input type="checkbox" class="form-check-input" v-model="questiontype.integerrange">
                    </div>
                </div>

            </div>
            <div class=" mb-2 row">
                <div class="col-3">
                    <small>{{$t('hexsteps')}}:</small> 
                </div>
                <div class="col-9 text-start">
                    <div class="input-group input-group-sm">
                        <input type="text" class="form-control" v-model="questiontype.hexsteps">
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import QuestionTypeService from "@/helpers/apiservices/questiontype/questiontype.service"
import Notify from "@/helpers/notify";

export default {
    name: 'QuestionType',
    props:[
        'questiontype',
        'typeicons'
    ],
    data() {
      return {
        edit: false
      };
    },
    methods: {
        async deleteQuestionType() {
            Notify.loadData("deleted", this.delete);
        },
        async updateQuestionType() {
            Notify.loadData("updated", this.update);
            
        },
        async delete(){
            await QuestionTypeService.delete(this.questiontype);
            this.$emit('loadInitialData');
        },
        setEdit(){
            this.edit = !this.edit;
        },
        async update(){
            await QuestionTypeService.update(this.questiontype);
            this.setEdit();
            this.$emit('loadInitialData');
        },

    }
    
}
</script>

<style>
    td{padding:0px !important}
</style>
    