
<template>
    <div class="container-fluid">
        <div class="row flex-nowrap">
            <div class="col-3"></div>
            <div class="mt-5 col-8 py-3">
              <div class="ms-3 text-start d-flex align-items-center">
                <i class="fs-1"><font-awesome-icon icon="fa-dashboard" /></i>
                <h5 class="ms-2 card-title">{{ $t("dashboard") }}</h5>  
              </div>
              <div class="mt-3 ms-4 d-flex flex-column align-items-start text-start">
                <span>
                  {{$t('dashboardinfo1')}}
                </span>
                <span>
                  {{$t('dashboardinfo2')}}
                </span>
                <span>
                  {{$t('dashboardinfo3')}}
                </span>
              </div>
              <div class="mt-4 row">
                  <div class="p-0 col-4 d-flex justify-content-start">
                    <flat-pickr  v-model="date" :placeholder="$t('selectDateRange')" :config="flatpickrConfig" id="selectEventStyle"  @on-close="onCalendarClose"/>
                    <div class="input-group-append">
                      <button class="p-0 ps-2  btn btn-default" type="button" :title="$t('clear')" @click="cleardates()">
                        <font-awesome-icon icon="fa-times" />
                        <span aria-hidden="true" class="sr-only">{{$t('clear')}}</span>
                      </button>
                    </div>
                  </div>
                  <div class="col-4">
                      <select v-model="selectEvent" @change="onChange($event)" id="selectEventStyle" placeholder="velg">
                        <option value="" disabled>{{$t('chooseSurvey')}}</option>
                        <option v-for="survey in surveys" :value="survey.id">
                            {{ survey.title }}
                        </option>
                      </select>
                  </div>
                  <div class="col-2">
                      <button @click="search()" id="searchStyle" >
                        {{$t('search')}}
                      </button>
                  </div>
                  <div class="col-2">
                      <button @click="convertToPDF()" id="convertToPDFStyle" >
                        {{$t('download')}}
                      </button>
                  </div>
              </div>
              <div class="mb-4 row">
                <p class="text-warning" v-if="errorMessage">{{ $t("dashboardrrmsg") }}</p> 
              </div>
            <!-- Nav tabs -->
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a class="nav-link active" data-bs-toggle="tab" href="#" @click="activateTextView()">{{ $t("actions") }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#" @click="activateOverview()" >{{ $t("overview") }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#" @click="activateDetailedview()">{{ $t("detailedview") }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#" @click="activateResponseDistributionView()">{{ $t("responsedistribution") }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#" @click="activateReportView()">{{ $t("report") }}</a>
              </li>
              
            </ul>

            <div>
              <vue3-html2pdf
              :show-layout="true"
              :float-layout="false"
              :enable-download="false"
              :preview-modal="true"
              :paginate-elements-by-height="1400"
              filename="nightprogrammerpdf"
              :pdf-quality="2"
              :manual-pagination="true"
              pdf-format="a4"
              :pdf-margin="10"
              pdf-orientation="landscape"
              pdf-content-width="800px"
              ref="html2Pdf"
              :htmlToPdfOptions="htmlToPdfOptions"
             >
                 <template  v-slot:pdf-content>
                  
                    <template v-if="selectedSurvey" :key="selectedSurvey.id">
                        <SurveyStatistics :survey="selectedSurvey" :dateRange="dateRange" :view="currenttabview" :actiontemplate="selectedSurvey.questionnaire.actiontemplate"></SurveyStatistics>
                    </template>
                
                 </template>
             </vue3-html2pdf>
            </div>


        </div>
    </div>
    </div>
  </template>


<script>

import Notify from "@/helpers/notify";
import SurveyService from "@/helpers/apiservices/survey/survey.service";
import SurveyStatistics from '@/components/SurveyStatistics';
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import FlatpickrHelper from "@/helpers/settings/flatpickr";
import Vue3Html2pdf from 'vue3-html2pdf'

import * as moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default {
    name: 'Dashboard',
    data() {
      return {
        dateRange: {
            start: new Date(),
            end: new Date(),
        },
        date: null,
        flatpickrConfig: FlatpickrHelper.config(),
        surveys: [],
        selectedSurvey: null,
        selectedSurveyId: "",
        selectEvent: {},
        canvas: null,
        currenttabview: {
          overview: false,
          detailedview: false,
          textview: true,
          reportview: false,
          responsedistributionview: false
        },
        errorMessage: false,
        htmlToPdfOptions: {
          margin: 20,
          html2canvas: {
            useCORS: true,
            width: 800,
            scale: 1, scrollY: 0
          },
        }
      };
    },
    components: {
        SurveyStatistics,
        VueCal,
        flatPickr,
        Vue3Html2pdf

    },
    mounted() {
        Notify.loadData(null, this.loadInitialData);
        this.dateRange = { start: moment().subtract(1, "year"), end: moment().add(1, "month") };
    },
    methods: {
      async loadInitialData() {
        this.surveys = await SurveyService.surveys();
      },
      async onChange(selectEvent) {
        this.selectedSurveyId = selectEvent.target.value;
      },
      async search(){
        if(this.date === null || this.selectedSurveyId === ""){
          this.errorMessage = true;
        }
        else{
          this.errorMessage = false;
          Notify.loadData(null, this.getSurvey);
        }
      },
      onCalendarClose: function(selectedDates, dateStr, instance){
        if(selectedDates.length === 2){
          this.dateRange = { start: moment(selectedDates[0]), end: moment(selectedDates[1]) };
          this.errorMessage = false;
        }
        else{
          this.errorMessage = true;
        }
      },
      cleardates(){
        this.date = null;
      },
      async activateOverview(){
        this.currenttabview.overview = true;
        this.currenttabview.detailedview = this.currenttabview.textview = this.currenttabview.responsedistributionview = this.currenttabview.reportview = false;
      },
      async activateDetailedview(){
        this.currenttabview.detailedview = true;
        this.currenttabview.overview = this.currenttabview.textview = this.currenttabview.responsedistributionview = this.currenttabview.reportview = false;
      },
      async activateTextView(){
        this.currenttabview.textview = true;
        this.currenttabview.detailedview = this.currenttabview.overview = this.currenttabview.responsedistributionview = this.currenttabview.reportview = false;
      },
      async activateResponseDistributionView(){
        this.currenttabview.responsedistributionview = true;
        this.currenttabview.detailedview = this.currenttabview.overview = this.currenttabview.textview = this.currenttabview.reportview = false;
      },
      async activateReportView(){
        this.currenttabview.reportview  = true;
        this.currenttabview.detailedview = this.currenttabview.overview = this.currenttabview.textview = this.currenttabview.responsedistributionview = false;
      },
    
      async getSurvey() {
        //Clean selected survey
        this.selectedSurvey = null;
        var survey = await SurveyService.survey(this.selectedSurveyId);
        this.selectedSurvey = survey;
      },

      convertToPDF() {

        this.$refs.html2Pdf.generatePdf()
        /*
        html2canvas(document.querySelector("#my-element")).then((canvas) => {
          
          var pdf = new jsPDF("p", "pt", [canvas.width, canvas.height]);
          var pageHeight = pdf.internal.pageSize.height;
          var pageWidth = pdf.internal.pageSize.width;
          var imageData = canvas.toDataURL("image/png");
          var imageHeight = canvas.height;
          var imageWidth = canvas.width;
          var position = 0;
          while (position < imageHeight) {
            //pdf.addPage();
            pdf.addImage(imageData, "PNG", 20, 20, (pageWidth-60), imageHeight);
            pdf.addPage();
            position += pageHeight;
          }
          // Delete the last page of the PDF because it is blank
          var lastPageNumber = pdf.internal.getNumberOfPages();
          pdf.deletePage(lastPageNumber);
          pdf.save("ispdf.pdf");
        });
        */
    },
    maxDate() {
      const max = moment(this.dateRange.start).add(2, "month");
      return max.format("YYYY-MM-DD");
    },
  },
  computed:{
    formattedstartdate: function(){
        return moment(this.dateRange.start).format('DD-MM-YYYY');
    },
    formattedenddate: function(){
        return moment(this.dateRange.end).format('DD-MM-YYYY');
    },
  }

}
</script>

<style>
#dateRangeStyle {
  height: 100%;
  width: 90%;
  border-radius: 10px;
}
#selectEventStyle {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  border-width: 1px;
  color: #0f0f0f;
  font-size: min(max(2vw, 8px), 14px);
}
#convertToPDFStyle {
  background-image: url("../../../assets/icons8-pdf-48.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 15%;
  background-color: white;
  border: 0.75px solid #5f5f5f;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  font-size: min(max(2vw, 8px), 13px);
}
#searchStyle {
  background-color: white;
  border: 0.75px solid #5f5f5f;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  font-size: min(max(2vw, 8px), 13px);
}

</style>
    