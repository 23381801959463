const Apexchart = {
    piesettings(id = "pieapexchart", showToolbar = true) {
      return {
        chart: {
          id: id,
          type: 'donut',
        },
        plotOptions: {
            pie: {
              startAngle: -90,
              endAngle: 270
            }
        },
        
        colors:['#719541', '#A1D55D', '#D5A55D', '#D5735D', '#808080' ],
        dataLabels: {
          enabled: false
        },
        fill: {
          type: 'gradient',
        },
        title: {
          text: ''
        },
        legend: {
          position: 'left',
          offsetY: 80
        },
          responsive: [{
            breakpoint: 480,
          }]
      } 
    },

    areasettings(id = "areapexchart"){
      return{
        chart:{
          id: id,
          type: 'area',
          zoom: {
            autoScaleYaxis: true
          },
          locales: [{
            "name": "nb",
            "options": {
              "months": [
                "Januar",
                "Februar",
                "Mars",
                "April",
                "Mai",
                "Juni",
                "Juli",
                "August",
                "September",
                "Oktober",
                "November",
                "Desember"
              ],
              "shortMonths": [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "Mai",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Okt",
                "Nov",
                "Des"
              ],
              "days": [
                "Søndag",
                "Mandag",
                "Tirsdag",
                "Onsdag",
                "Torsdag",
                "Fredag",
                "Lørdag"
              ],
              "shortDays": ["Sø", "Ma", "Ti", "On", "To", "Fr", "Lø"],
              "toolbar": {
                "exportToSVG": "Last ned SVG",
                "exportToPNG": "Last ned PNG",
                "exportToCSV": "Last ned CSV",
                "menu": "Menu",
                "selection": "Velg",
                "selectionZoom": "Zoom: Velg",
                "zoomIn": "Zoome inn",
                "zoomOut": "Zoome ut",
                "pan": "Skyving",
                "reset": "Start på nytt"
              }
            }
          }],
          defaultLocale: "nb"
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'datetime',
          labels: {
            format: 'ddd dd MMM HH:00',
            datetimeUTC: false
          },
          tickAmount: 1,
        },
        tooltip: {
          x: {
            format: 'ddd dd MMM HH:00'
          }
        }, 
        dataLabels: {
          enabled: false,
        },
      }
    },
    barsettings(id = "barapexchart", showToolbar = true) {
      return {
        chart: {
          id: id,
          type: 'bar',
          width: 300,
          height: 250
        },
        colors: ['#8DCC3B', '#B3D873', '#CDD067', '#EAC659', '#FAB656', '#F69E64', '#ED4E3B' ],
        plotOptions: {
          bar: {
            borderRadius: 2,
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
            distributed: true
          }
        },
        dataLabels: {
          enabled: false
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 180
            },
            legend: {
              position: 'left'
            }
          }
        }]
        
      } 
    }
 
  }
  
  
  export default Apexchart;