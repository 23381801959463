<template>
    <div>
        <div v-if="!showsubquestions" class="m-lg-1" >
            <p class="mb-5 mt-lg-5 mt-md-3 mb-md-3" >{{surveytitle}}</p>
            <table class="table table-borderless">
                <template v-for="q in orderedquestions" :key="q.id">
                    <Query :query="q"></Query>
                </template>
                <!-- only for large and medium screens -->
                <tr class="d-none d-lg-table-row d-md-table-row">
                    <td colspan="3"></td>
                    <td colspan="2" class="text-start xstext">{{$t('notsatisfied')}}</td>
                    <td colspan="2" class="text-end xstext">{{$t('verysatisfied')}}</td>
                </tr>
            </table>
        </div>

        <template v-if="showsubquestions">
            <p class="mb-5 mt-lg-5 mt-md-3 mb-md-3" >{{currentSubQuestion.text}}</p>
            <div>
                <table class="table table-borderless">
                    <template v-for="sq in orderedsubquestions" :key="sq.id">
                        <SubQuery :query="currentSubQuestion" :subquery="sq" ></SubQuery>
                    </template>
                </table>
                
            </div>
        </template>

        <div class="row mt-2">
            <div class="col text-end">
                <button class="btn btn-sm btn-dark btn-md btn-block" @click="onClick">{{$t('nextupper')}} <font-awesome-icon icon="fa-solid fa-arrow-right" /></button>
            </div>
        </div>

    </div>
</template>


<script>

    import router from '@/router';
    import Query from "@/components/survey/Query";
    import SubQuery from "@/components/survey/SubQuery";
    import { useRoute } from 'vue-router'
    import ResponseService from '@/helpers/apiservices/response/response.service'
    import Tips from '@/components/actiontemplates/tipdaydiggminskole'
    import { tipofthedaystore } from '@/stores/tipoftheday.store.js'


export default {
    name: 'SingleColumnQuestions',
    props:{
        title: {type: String},
        questionnaire: {type: Object},
    },
    components: {
        Query,
        SubQuery
    }, 
    data() {
        return {
            surveytitle: '',
            showsubquestions: false, 
            timestartepoch: Number,
            noofclicks: Number,
            surveypublicid: String,
            currentSubQuestion: null,
            tipofthedaystore
        }
    },
    mounted() {
        this.loadInitialData();
    },
    methods: {
        async loadInitialData() {
            const route = useRoute();
            if(route.query.id){
                this.surveypublicid = route.query.id;
            }
            this.timestartepoch = Date.now(); //Milliseconds epoch
            this.noofclicks = 0;
            this.surveytitle = this.questionnaire.survey.title;
        },
        onClick : function(event) {
            //first one to show:
            if(this.currentSubQuestion === null){
                this.currentSubQuestion = this.questionnaire.questions.find(({ showsubanswer }) => showsubanswer === true);
            }
            else{ 
                this.currentSubQuestion = this.findNextSubQuestion(this.currentSubQuestion.id);

            }
            //If last one or no subanswers required - finish questionnaire
            if(this.currentSubQuestion === undefined || this.currentSubQuestion === null){
                this.showsubquestions = false;
                this.finishQuestionnaire();
            }
            else{
                this.showsubquestions = true;
            }
            
        },
        findNextSubQuestion: function(currentid){
            const currindex = this.questionnaire.questions.findIndex(question => question.id === currentid);
            return this.questionnaire.questions
                .slice(currindex+1)
                .find(({ showsubanswer }) => showsubanswer === true);

        },
        async finishQuestionnaire()
        {
            //Finished- store answer
            const responsesurvey = this.createResponseSurvey();

            //Thank you
            router.push({
                path: "Thanks",
                query: {survey: this.surveypublicid}
            });

            //Give message to thanks:
            this.tipofthedaystore.tipoftheday = ''; //Global store, so clean the previous message
            this.tipofthedaystore.tipoftheday = this.createTipOfTheDay(responsesurvey);

            ResponseService.store(responsesurvey);
        },
        createTipOfTheDay(responsesurvey){
            //If actiontemplate, create tip of the day, only support id = 1 DIGG MIN SKOLE
            if(this.questionnaire.actiontemplate !== null && this.questionnaire.actiontemplate.id === 1){
                return Tips.tipOfTheDay(responsesurvey);
            }
            return "";
        },
        createResponseSurvey: function(){
            //Map questionnaire object to ReposenseSurvey object for server storage
            const responsesurvey = {
                surveypublicid: this.surveypublicid,
                timestartepoch: this.timestartepoch,
                timeendepoch: Date.now(),
                noofclicks: this.noofclicks,
                
            }

            const responsequestions = [];
            
            //Add answer to each question
            this.questionnaire.questions.forEach(function(question) {

                const responsequestion = {
                    questionid: question.id,
                    booleanvalue: question.booleanvalue === (undefined) ? null : question.booleanvalue ,
                    numericvalue: question.numericvalue === 0 ? question.numericvalue : (question.numericvalue === (undefined | null) ? null : question.numericvalue) ,
                    textvalue: question.textvalue === (undefined) ? null : question.textvalue,
                };

                const responsesubquestions = [];

                //Add answer to each subquestion
                if(question.subquestions !== undefined){

                    question.subquestions.forEach(function(subquestion) {

                        const responsesubquestion = {
                                subquestionid: subquestion.id,
                                booleanvalue: subquestion.booleanvalue === (null | undefined) ? null : subquestion.booleanvalue ,
                                numericvalue: subquestion.numericvalue === 0 ? subquestion.numericvalue : ( subquestion.numericvalue  == (null | undefined) ? null : subquestion.numericvalue) ,
                                textvalue: subquestion.textvalue == (null | undefined) ? null : subquestion.textvalue,
                        }

                        responsesubquestions.push(responsesubquestion);
                    });
                }

                responsequestion.responsesubquestions = responsesubquestions;
                responsequestions.push(responsequestion);    

            });

            responsesurvey.responsequestions = responsequestions;
            return responsesurvey;
        },

        onBackClick : function(event) {
            router.push({
                path: "/",
            });
        },
    },
    computed: {
        orderedquestions: function(){
            if(this.questionnaire.questions){
                var sorted = this.questionnaire.questions.sort((a, b) => {return a.orderseq - b.orderseq;});
                return sorted;
            }
        },
        orderedsubquestions: function(){
            if(this.currentSubQuestion.subquestions){
                var sorted = this.currentSubQuestion.subquestions.sort((a, b) => {return a.orderseq - b.orderseq;});
                return sorted;
            }
        },
    },

}
</script>
