<template>
    <div class="modal fade" id="roomModal" tabindex="-1" aria-labelledby="roomLabel" aria-hidden="true">
        <form @submit.prevent="store">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="roomLabel">{{$t('newclassroom')}}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <select v-model="selectedbuilding" class="form-select form-select-lg mb-3" required>
                            <option disabled value="0">{{$t('chooseschool')}}</option>
                            <option v-for="building in buildings" v-bind:value="building">
                                {{ building.text }}
                            </option>
                        </select>
                        <input type="text" class="mb-2 form-control" aria-describedby="inputGroup-sizing-default" :placeholder="$t('name')" v-model="room.text" required>
                        <input type="text" class="mb-2 form-control" aria-describedby="inputGroup-sizing-default" :placeholder="$t('description')" v-model="room.description" required>
                        <input type="text" class="mb-2 form-control" aria-describedby="inputGroup-sizing-default" :placeholder="$t('roomid')" v-model="room.identification">
                        <p class="mb-2 csm">{{$t('roomidinfo')}}</p>
                        <input type="number" class="mb-2 form-control" aria-describedby="inputGroup-sizing-default" :placeholder="$t('nopeopleoriginal')" v-model="room.nopeopleoriginal">
                        <input type="number" class="form-control" aria-describedby="inputGroup-sizing-default" :placeholder="$t('nopeoplecurrent')" v-model="room.nopeoplecurrent">
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{$t('close')}}</button>
                        <button type="submit" class="btn btn-primary">{{$t('addnew')}}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>


    import Notify from "@/helpers/notify";
    import BuildingService from "@/helpers/apiservices/building/building.service"
    import RoomService from "@/helpers/apiservices/room/room.service"
    import { Modal } from 'bootstrap'

    export default {
        name: 'RegisterRoomModal',
        data() {
          return {
            room: {
                id: 0,
            },
            selectedbuilding: {
                id: 0,
                name: "",
                description: ""
            },
            buildings:[],
          };
        },
        components: {
        },
        mounted() {
            var vm=this;
            var roomModal = document.getElementById('roomModal');
            //var modal = Modal.getOrCreateInstance(roomModal);
            roomModal.addEventListener('shown.bs.modal', function () {
                vm.loadInitialData();
            });

            Notify.loadData(null, this.loadInitialData);
        },

        methods: {
            async loadInitialData() {
                this.buildings = await BuildingService.buildings();
            },
            async store(){
                Notify.loadData(null, this.storeData);
            },
            async storeData(){
                await RoomService.store(this.selectedbuilding.id, this.room);

                //Hide modal
                var myModal = document.getElementById('roomModal');
                var modal = Modal.getOrCreateInstance(myModal)
                modal.hide()
            
                //emit update on schools
                this.$emit('reloadRooms');
            },
            async refreshBuildings(){
                
            }
        }
        
    }
</script>
                    