<template>

    <div class="mt-5 row">
        <AreaChart :responses="responses"></AreaChart>
    </div>
  </template>

  <script>

    import AreaChart from '@/components/charts/AreaChart';

    export default {
      name: 'ResponseDistribution',
      components:{
        AreaChart
      },
      props:{
        responses: {type: Array}
      },
    }
    </script>
  
    <style>
  
    </style>