<template>
    <div class="container-fluid">
        <div class="row flex-nowrap">
            <div class="col-3"></div>
            <div class="mt-5 col-8 py-3">
                <div class="ms-3 text-start d-flex align-items-center">
                    <i class="fs-1"><font-awesome-icon icon="fa-file-invoice" /></i> 
                    <h5 class="ms-2 card-title">{{$t('questionnaire')}}</h5>   
                  </div>
                  <div class="mt-3 ms-4 d-flex flex-column align-items-start text-start">
                    <span>
                      {{$t('qeditinfo1')}}
                    </span>
                    <span>
                     {{$t('qeditinfo2')}}
                    </span>
                    <span>
                     {{$t('qeditinfo3')}}
                    </span>
                    <span>
                     {{$t('qeditinfo4')}}
                    </span>
                  </div>

                  <div class="mt-5 p-3 bg-secondary bg-opacity-10">

                    <div class="mt-2 row">
                        <div class="col-6 text-start">
                            <div class="input-group input-group-sm mb-3">
                                <span class="input-group-text" id="inputGroup-sizing-sm"><font-awesome-icon icon="fa-key" class="me-1" />ID:</span>
                                <span class="input-group-text" id="inputGroup-sizing-sm">{{questionnaire.id}}</span>
                            </div>
                        </div> 
                        <div class="col-6 text-end"><button type="submit" @click="saveQuestionnaire" class="m-1 btn btn-isprimary btn-sm ">{{$t('savequestionnaire')}}</button></div>
                    </div>

                    <form class="needs-validation" @submit.prevent="saveQuestionnaire">
                        <div class="mt-2 row">
                            <div class="col-3 text-start">
                            <label  for="surveyName">{{$t('qnairename')}} </label>
                            </div>
                            <div class="col-9 text-start">
                                <input type="text" maxlength="250" class="form-control" id="Title" v-model="questionnaire.title" required>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3 text-start">
                            <label  for="surveyName">{{$t('description')}} </label>
                            </div>
                            <div class="col-9 text-start">
                                <textarea maxlength="250" class="form-control" id="exampleFormControlTextarea1" rows="2" v-model="questionnaire.description" required></textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3 text-start">
                            <label >{{$t('useactiontemplate')}} </label>
                            </div>
                            <div class="col-9 text-start">
                                <select v-model="questionnaire.actiontemplate" class="form-select-sm">
                                    <option disabled value="0">{{$t('chooseactiontemplate')}}</option>
                                    <option v-for="actiontemplate in actiontemplates" v-bind:value="actiontemplate">
                                        {{ actiontemplate.title }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3 text-start">
                                <label for="subtitleStart">{{$t('subtitlestart')}}</label>
                            </div>
                            <div class="col-9 text-start">
                                <input type="text" maxlength="250" class="form-control" id="subtitleStart" v-model="questionnaire.subtitlestart">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3 text-start">
                                <label for="subtitleEnd">{{$t('subtitleend')}}</label>
                            </div>
                            <div class="col-9 text-start">
                                <input type="text" maxlength="250" class="form-control" id="subtitleEnd" v-model="questionnaire.subtitleend" >
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3 text-start">
                                <label for="numberOfCols">{{$t('numberofcols')}}</label>
                            </div>
                            <div class="col-9 text-start">
                                <input type="number" class="form-control" id="numberOfCols" v-model="questionnaire.numberofCols" min="1">
                            </div>
                        </div>
                        <hr>
                        <div class="ms-5 fs-6 text-start text-danger fw-bold">{{errorMessage}} </div>

                        <component is="Question" 
                            :question="q" 
                            :questionnaireid="questionnaire.id" 
                            :index="index" 
                            :questiontypes="questiontypes" 
                            :icons="icons" 
                            v-for="(q, index) in sortedQuestions" 
                            :key="q.id" 
                            @removeItem="removeQuestion">  
                        </component>
                        <div class="me-5 text-end">
                            <button type="button" @click="newQuestion" class="m-1 btn btn-islink btn-sm fw-bold">{{$t('newquestion')}}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Question from '@/components/Question'
import QuestionTypeService from "@/helpers/apiservices/questiontype/questiontype.service"
import QuestionnaireService from "@/helpers/apiservices/questionnaire/questionnaire.service"
import IconService from '@/helpers/apiservices/icon/icon.service'
import ActionTemplateService from "@/helpers/apiservices/actiontemplate/actiontemplate.service";
import Notify from "@/helpers/notify";
import { useAuthStore } from '@/stores/auth.store';
import { isProxy, toRaw } from 'vue';
import { useRoute } from 'vue-router'

export default {
    name: 'Questionnaire',
    components: {
        Question
    },
    data() {
      return {
        questionnaire: {
            id: 0,
            title:'', 
            description:'',
            ownerid: useAuthStore().user.id,
            questions: []
        },
        questiontypes:[],
        icons: [],
        actiontemplates: [],
        errorMessage: '',
      };
    },
    mounted() {
        this.newQuestion();
        Notify.loadData(null, this.loadInitialData);
    },
    methods: {
        async loadInitialData() {
            const route = useRoute();
            if(route.query.id){
                this.questionnaire = await QuestionnaireService.questionnaire(route.query.id);
            }

            this.questiontypes = await QuestionTypeService.questiontypes();
            this.icons = await IconService.icons();
            this.actiontemplates = await ActionTemplateService.actiontemplates(); 
        },
        async saveQuestionnaire() {
            this.errorMessage = '';
            if(this.validObject){
                Notify.loadData("saved", this.save);
            }
            else
                this.errorMessage = this.$t('emsgMissingQTypes');
            
        },
        async save() {
            //await
            let rawData = this.questionnaire;

            if (isProxy(this.questionnaire)){
                rawData = toRaw(this.questionnaire)
            }

            if(this.questionnaire.id === 0 ){
                this.questionnaire = await QuestionnaireService.store(rawData);
            }
            else{
                await QuestionnaireService.update(rawData);
            }
        },
        newQuestion(){
            this.questionnaire.questions.push({id: 0, text: ''});
        },
        removeQuestion(index){
            this.questionnaire.questions.splice(index, 1);
        }
    },
    computed: {
        validObject: function() {
            let emptyqtypes = this.questionnaire.questions.filter(q => {
                return q.type === 0
            });
            let emptyqicons = this.questionnaire.questions.filter(q => {
                return q.icon === 0
            });

            return emptyqtypes.length === 0 || emptyqicons.length === 0;
        },
        sortedQuestions() {
            return this.questionnaire.questions.sort((a, b) => a.orderseq - b.orderseq);
        }
    },
}
</script>

<style>

</style>
    