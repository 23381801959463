<template>

  <div style="display: contents"> <!-- rendered invisible -->
    <QuestionInfoModal></QuestionInfoModal>
      <div style="display: contents">
        
        <!--Only for small screens-->
        <div class=" rounded d-lg-none d-md-none mb-3" style="background-color: #F9F0F0;"> 
          <div class="row">
            <div class="col-3 align-self-center"><img class="titleicon" alt="DIGG MIN SKOLE logo" :src="require(`@/assets/${question.icon.filename}`)"></div>
            <div class="col-9">
              <div class="mt-2 mb-2 row">
                <div class="col-12 text-start">
                  <span class="text-uppercase qtext">{{ question.text}}</span>
                  <button v-if="question.showinfo" class="align-self-end btnemoji" data-bs-toggle="modal" 
                    data-bs-target="#questionInfoModal" :data-bs-text="question.infotext">
                    <font-awesome-icon icon="d-flex align-items-bottom fa-circle-info" :style="{ color: '#adb5bd' }" />
                  </button>
                  <button v-if="hasSpeech" class="btnemoji" @click="onTextToSpeech">
                    <font-awesome-icon icon="fa-volume-high" :style="{ color: 'gray' }" />
                  </button>
                </div>
                <div class="mt-2 col-12">
                  <div class="row no-gutters">
                    <div class="col-4">
                      <button class="btnemoji rounded-circle qicon" @click="onClick" id="confirm" :class="{'faded' : confirmfade , 'confirmselected' : selected.confirm, 'confirm' : !selected.confirm}" >
                        <span class="fw-bold qtext">{{$t('yeslabel')}}</span>
                      </button>
                    </div>
                    <div class="col-4">
                      <button class="btnemoji rounded-circle qicon " @click="onClick" id="notconfirm" :class="{'faded' : notconfirmfade , 'notconfirmselected' : selected.notconfirm, 'notconfirm' : !selected.notconfirm}">
                        <span class="fw-bold qtext">{{$t('nolabel')}}</span>
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        
        <!--Only for large and medium screens-->
          <td class="d-none d-lg-table-cell d-md-table-cell pt-2 pb-2 text-start " v-if="hasSpeech">
            <button class=" align-items-center btnemoji" @click="onTextToSpeech">
              <font-awesome-icon icon="fa-volume-high" :style="{ color: 'gray' }" />
            </button>
          </td>
          <td valign="bottom" class="d-none d-lg-table-cell d-md-table-cell pt-2 pb-2  text-end d-flex align-items-end">
            <img class="titleicon" alt="DIGG MIN SKOLE logo" :src="require(`@/assets/${question.icon.filename}`)">
          </td>
          <td class="d-none d-lg-table-cell d-md-table-cell pt-2 pb-2 text-start " style="padding-left: 30px !important">
            <span class="text-uppercase qtext">{{ question.text}}</span>
            <button v-if="question.showinfo" class="align-self-end btnemoji" data-bs-toggle="modal" 
              data-bs-target="#questionInfoModal" :data-bs-text="question.infotext">
              <font-awesome-icon icon="d-flex align-items-bottom fa-circle-info" :style="{ color: '#adb5bd' }" />
            </button>
          </td>
          <td class="d-none d-lg-table-cell d-md-table-cell pt-2 pb-2 text-start ">
            <button class="btnemoji rounded-circle qicon" @click="onClick" id="confirm" :class="{'faded' : confirmfade , 'confirmselected' : selected.confirm, 'confirm' : !selected.confirm}" >
              <span class="fw-bold">{{$t('yeslabel')}}</span>
            </button>
          </td>
          <td class="d-none d-lg-table-cell d-md-table-cell pt-2 pb-2 text-start ">
            <button class="btnemoji rounded-circle qicon " @click="onClick" id="notconfirm" :class="{'faded' : notconfirmfade , 'notconfirmselected' : selected.notconfirm, 'notconfirm' : !selected.notconfirm}">
              <span class="fw-bold">{{$t('nolabel')}}</span>
            </button>
          </td>
      </div>
      <tr>
        <td colspan="7" class="d-none d-lg-table-cell d-md-table-cell"><hr class="mt-2 mb-2"></td>
      </tr>
  </div>

</template>

<script>

    import router from '@/router';
    import QuestionInfoModal  from "@/components/QuestionInfoModal";
    export default {
      name: 'TrueFalseSubQuery',
      props:{
          question: {type: Object}
      },
      data() {
          return { 
            selected: {
              confirm: false,
              notconfirm: false
            },
          }
      },
      components: {
        QuestionInfoModal
      },
      mounted() {
      },
      methods: {
          onClick: function(event){
              var t = event.currentTarget.id;
              
              //Reset style
              this.selected.confirm = this.selected.notconfirm = false;

              //default values. TrueFalse only supports true or false
              this.question.booleanvalue = null;
              this.question.textvalue = null;

              if(t === "confirm"){ 
                  this.selected.confirm = !this.selected.confirm;
                  this.question.booleanvalue = true;
                  this.question.showsubanswer = false;
              }
              else if(t === "notconfirm"){ 
                  this.selected.notconfirm = !this.selected.notconfirm;
                  this.question.booleanvalue = false;
                  this.question.showsubanswer = this.question.type.addsubquestions;
              }
          },
          onTextToSpeech : function(event) {
                if (this.hasSpeech) {
                    var text = this.question.text;
                    text = text.replace('NÅ', ', NÅ'); //Create some space/pause before speaking the norwegian word NÅ
                    const utterThis = new SpeechSynthesisUtterance(text);
                    
                    const synth = window.speechSynthesis;
                    utterThis.rate = 0.4;
                    utterThis.lang = 'nb-NO';
                    synth.speak(utterThis);
                }
            },

        },
        computed: {
          hasSpeech: function(){
            if ('speechSynthesis' in window) {
              return true;
            }
            return false;
          },
          nonselected: function(){
            //non selected yet
            if(this.selected.confirm === false && this.selected.notconfirm === false ){
              return true;
            }
            return false;
          },     
          confirmfade: function(){
            //non selected yet
            if(this.nonselected){
              return false;
            }

            if(this.selected.confirm === false){
              return true;
            }
            else
              return false;
          },
          notconfirmfade: function(){
            //non selected yet
            if(this.nonselected){
              return false;
            }

            if(this.selected.notconfirm === false){
              return true;
            }
            else
              return false;
          },               
      },
      }
  </script>

  <style scoped>

    .faded {
      opacity: 0.3;
    }
    .btnemoji{
      background-color: transparent;
      border-style: none;
    }

    .confirm{
      background-color: #A1D55D ;
    }
    .notconfirm{
      background-color: #D5735D;
    }
    .confirmselected{
      background-color: #A1D55D !important ;
    }
    .notconfirmselected{
      background-color: #D5735D !important ;
    }
    .faded {
      opacity: 0.3;
    }

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
      .titleicon{
        height: 40px; 
        width: 40px;
      }
      .qtext{
        font-size: 12px;
      }
      .qicon{
        height: 30px; 
        width: 30px;
      }

    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
      .titleicon{
        height: 40px; 
        width: 40px;
      }
      .qtext{
        font-size: 12px;
      }
      .qicon{
        height: 30px; 
        width: 30px
      }
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
      .titleicon{
        height: 50px; 
        width: 50px;
      }
      .qtext{
        font-size: 24px;
      }
      .qicon{
        height: 40px; 
        width: 40px
      }
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
      .titleicon{
        height: 40px; 
        width: 40px;
      }
      .qtext{
        font-size: 24px;
      }
      .qicon{
        height: 40px; 
        width: 40px
      }
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
      .titleicon{
        height: 40px; 
        width: 40px;
      }
      .qtext{
        font-size: 24px;
      }
      .qicon{
        height: 40px; 
        width: 40px
      }
    }
  </style>