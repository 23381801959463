<template>

    <div style="display: contents">
        <!-- Supporting slider for integerrange types -->
        <LSlider
            :question="question" 
            :text="question.text" 
            :icon="question.icon.filename" 
            :min="question.type.minint" 
            :max="question.type.maxint" 
            v-if="question.type.integerrange"
            @lsOnClick="lsliderOnClick" >
        </LSlider>

        <!-- Supporting icon button select/unselect for boolean types -->
        <LIconButton2 @btnOnClick="liconbtnOnClick" :text="question.text" :icon="question.icon.filename" v-if="question.type.booleanvalue" ></LIconButton2>
    </div>
    

</template>

<script>
    import LIconButton2 from "@/components/LIconButton2";
    import LSlider from "@/components/survey/LSlider";

    export default {
    name: 'SliderQuery',
    props:{
        question: {type: Object}
    },
    components: {
            LIconButton2,
            LSlider
        }, 
    data() {
        return { 
            selected: false
        }
    },
    mounted() {
    },
    methods: {
        liconbtnOnClick : function() {
            this.selected = !this.selected;
            this.question.booleanvalue = this.selected ;

            //default values. LIconButton only supports true or false answers
            this.question.numericvalue = null;
            this.question.textvalue = null;
        },
        lsliderOnClick : function(value) {
            this.question.numericvalue = value;
            //default values. LSlider only supports integer answers
            this.question.booleanvalue = null ;
            this.question.textvalue = null;
        },
      }
    
      }
  </script>

  <style>

/* Galaxy fold*/
@media only screen and (max-width: 280px) {
    .li-border{
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
}

@media only screen and (min-width : 480px) and (max-width : 800px) and (orientation : landscape) {
  /* Styles */
  .li-border{
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
}

@media only screen and (min-device-width : 800px) and (max-device-width : 1280px) and (orientation : landscape) {
  /* Styles */
  .li-border{
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 320px) and (orientation : portrait) {
  /* Styles */
  .li-border{
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  /* Styles */
  .li-border{
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (orientation : portrait)  {
    /* Styles */
    .li-border{
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    /* Styles */
    .li-border{
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
        border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    /* Styles */
    .li-border{
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
        border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .li-border{
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
}

  </style>