<template>
    <div class="modal fade" id="buildingModal" tabindex="-1" aria-labelledby="buildingLabel" aria-hidden="true">
        <form @submit.prevent="store">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="buildingLabel">{{$t('newschool')}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <input type="text" class="mb-2 form-control" aria-describedby="inputGroup-sizing-default" :placeholder="$t('name')" v-model="building.text" required>
                    <input type="text" class="form-control" aria-describedby="inputGroup-sizing-default" :placeholder="$t('description')" v-model="building.description" required>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{$t('close')}}</button>
                    <button type="submit" class="btn btn-primary">{{$t('addnew')}}</button>
                </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>

    import Notify from "@/helpers/notify";
    import BuildingService from "@/helpers/apiservices/building/building.service"
    import { Modal } from 'bootstrap'

    export default {
        name: 'RegisterBuildingModal',
        data() {
          return {
            building: {
                text: "",
                description: ""
            }
          };
        },
        components: {
        },
        mounted() {
        },

    
        methods: {
            async store(){
                await BuildingService.store({id: 0, text: this.building.text, description: this.building.description});

                //Hide modal
                var myModal = document.getElementById('buildingModal');
                var modal = Modal.getOrCreateInstance(myModal)
                modal.hide()
            
                //emit update on schools
                this.$emit('reloadBuildings');
            },
        }
        
    }
</script>
                    