<template>

    <div class="l2-col m-0 p-0">
      <div class="text-end me-1" :class="[isSelected ? 'selected' : 'unselected']">
        <font-awesome-icon icon="fa-solid fa-circle-check" :style="{ color: '#50C878' }" />
      </div>
      <div>
        <button class="l2-button" @click="onClick">
          <img class="l2btn-icon"  alt="IDGG MIN SKOLE logo" :src="require(`@/assets/${icon}`)">
          <p class="l2btn-text m-0 pt-3">{{ text }}</p>
        </button>
      </div>
  </div>

</template>

<script>
    import router from '@/router';

    export default {
      name: "LIconButton2",
      props: {
        icon : { type: String },
        text : { type: String }
      },
      data: function () {
        return {
          isSelected: false
        }
      },
      methods: {
        onClick : function(event) {
          this.isSelected = !this.isSelected;
          this.$emit('btnOnClick');
//
        },
      }
    };
</script>
<style lang="postcss">


.selected {
  visibility: visible;
}
.unselected {
  visibility: hidden;
}


.l2-button {
  @apply relative leading-none whitespace-no-wrap cursor-pointer bg-gray-400;
  @apply border border-gray-500 rounded text-center outline-none m-0;
  @apply text-pastel-black;

  /*box-sizing: border-box;*/
  transition: 0.1s;
  transition: all 0.3s;
  -webkit-appearance: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  background-color:transparent !important;
  border:none;
  display:inline-block;
  cursor:pointer;
  font-family:Arial;
  padding:16px 31px;
  text-decoration:none;
  margin-bottom: 25px;
}

.l2-button:active {
	position:relative;
	top:1px;
}
.l2-button:hover {
  background-color:whitesmoke;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .l2-button{
    margin-top: 2px;
    padding: 8px 15px !important;
  }
  .l2btn-text{
    font-size:12px; 
  }
  .l2btn-icon{
    width: 30px;
    height: 30px;
  }
  .l2-col{
    height: 120px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .l2-button{
    width: 150px; 
    height: 100px;
  }
  .l2btn-text{
    font-size:16px;
  }
  .l2btn-icon{
    width: 45px;
    height: 45px; 
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .l2-button{
    width: 300px; 
    height: 120px;
  }
  .l2btn-text{
    font-size:20px;
  }
  .l2btn-icon{
    width: 60px;
    height: 60px; 
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .l2-button{
    width: 300px; 
    height: 120px;
  }
  .l2btn-text{
    font-size:20px;
  }
  .l2btn-icon{
    width: 60px;
    height: 60px; 
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .l2-button{
    width: 200px; 
    height: 160px;
    font-size:28px;
  }
  .l2btn-text{
    font-size:20px;
  }
  .l2btn-icon{
    width: 80px;
    height: 80px; 
  }
}

</style>