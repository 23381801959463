const ErrorParser = {
  asMessage: function (error) {
    let message = "Unknown error";
    if (error.response.status == 400) {      
      let errMsg = error.response.data.error.message;
      if(!errMsg) errMsg = error.response.data.error;
      message = JSON.stringify(errMsg);
    }
    else {
      message = error.response.data.error;
    }
    return message
  }
}

export default ErrorParser;